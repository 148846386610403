<template>
<section class="author-section section-space">
      <div class="container">
          <div class="row">
              <div class="col-lg-3">
                  <AuthorSidebar
                  :content="userData.bio"
                  :followingnum="SectionData.authorSidebarDataTwo.sidebarWidget.followingNum"
                  :followingtext="SectionData.authorSidebarDataTwo.sidebarWidget.followingText"
                  :followernum="SectionData.authorSidebarDataTwo.sidebarWidget.followerNum"
                  :followertext="SectionData.authorSidebarDataTwo.sidebarWidget.followerText"
                  :avatars="SectionData.authorSidebarDataTwo.sidebarWidget.followersAvatar"
                  :links="userData.userLinks"
                  :datetext="userData.userJoinDate"
                  ></AuthorSidebar>
              </div><!-- end col -->
              <div class="col-lg-9 ps-xl-4">
                  <div class="author-items-wrap">
                      <h3>Featured Pieces</h3>
                      <div class="gap-2x"></div><!-- end gap -->
                      <div class="row g-gs">
                          <div class="col-md-6" v-for="post in userData.posts" :key="post._id">
                          <div class="card card-full">
                              <div class="card-image">
                                  <img :src="post.image" class="card-img-top" alt="art image">
                              </div>
                              <div class="card-body p-4">
                                  <h5 class="card-title text-truncate mb-0">{{ post.name  }}</h5>

                                  <div class="card-author mb-1 d-flex align-items-center">
                                      <span class="me-1 card-author-by">By</span>
                                      <div class="custom-tooltip-wrap">
                                          <router-link :to="''" class="custom-tooltip author-link">{{ post.creatorUsername }}</router-link>
                                      </div>
                                  </div>
                                  <div>
                                    <p class="small bg-light rounded p-2">{{ post.description }}</p>
                                  </div>
                                  <div class="item-detail-meta d-flex flex-wrap justify-content-between mb-3 mt-3">
                                    <!-- <span class="dot-separeted"></span> -->
                                    <span class="item-detail-text-meta text-black">{{post.createdOnDate}}</span>
                                    <!-- <span class="item-detail-text-meta">{{ createdOnDate }}</span> -->
                                    <!-- <span class="item-detail-text-meta"><i class="ni ni-eye"></i> {{post.views}}</span> -->
                                  </div>
                                  <!-- <div class="card-price-wrap d-flex align-items-center justify-content-between mb-3">
                                      
                                  </div> -->
                                  <a v-if="userData && user && userData.username === user.username" href="#" class="btn btn-sm bg-danger-dim" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="postToDelete=post">Delete</a>
                              </div><!-- end card-body -->
                              <router-link
                                  class="details"
                                    :to="'/' + post.creatorUsername + '/' + post.predictionUrl"
                                  >
                              </router-link>
                          </div><!-- end card -->
                      </div>
                      </div><!-- row -->
                  </div><!-- end author-items-wrap -->
              </div><!-- end col-lg-8 -->
          </div><!-- end row -->
      </div>
      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
            <div id="notificationToast" class="toast " role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header d-flex bd-highlight">
                <img src="../../images/v3-dark.png" class="rounded me-auto bd-highlight" width="100" height="100" alt="...">
                <strong class="bd-highlight p-0 fw-normal">{{ toastTitleText }}</strong>
                <!-- <small>Just now</small> -->
                <button type="button" class="btn-close bd-highlight mt-0 pb-3" data-bs-dismiss="toast" aria-label="Close"><i class="ni ni-cross"></i></button>
            </div>
            <div class="toast-body bg-white">
                {{ toastBodyText }}
            </div>
            </div>
        </div>
      <!-- Modal -->
      <div class="modal fade" id="deleteModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-body text-center">
                      <img :src="SectionData.deleteModal.img" alt="" class="mb-3">
                      <h4 class="modal-tilte mb-2">Are you sure?</h4>
                      <p class="modal-text">Are you sure you want to permanently delete this post?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm btn-default" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal" @click="deletePost()">Delete</button>
                  </div>
              </div>
          </div>
      </div>
  </section><!-- end author-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import { mapGetters } from "vuex"
import { useHead } from "@vueuse/head"
import { computed, reactive } from "vue"
import { useRoute } from "vue-router";
import { useStore } from 'vuex'
import { Toast } from "bootstrap";

export default {
  name: 'UserProfileSection',
  data () {
    return {
      SectionData,
      userJoinDate: null,
      userLinks: [],
      postToDelete: null,
      toastBodyText: "",
      toastTitleText: "",
      notificationToast: null,
    }
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    isLocationResolved() {
      return this.$store.state.meta.isLocationResolved;
    },
    ...mapGetters({
      user: "auth/authUser",
    }),
  },
  setup() {
    const route = useRoute()
    const store = useStore();
    let userData = reactive({

    });
    (async () => {
        store.dispatch("user/getUserInfoByUsername", route.params.username)
        .then((res) => {
            // console.log(res.data.user)
            userData.username = route.params.username;
            userData.userLinks = [];
            userData.bio = res.data.user.bio;
            userData.userJoinDate = new Date(res.data.user.createdAt).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
        if (res.data.user.websiteUrl != null && res.data.user.websiteUrl.length > 0) {
            let websiteName = res.data.user.websiteUrl;
            if (res.data.user.websiteUrl.includes("https://")) {
                websiteName = res.data.user.websiteUrl.split("https://")[1];
            }
            const last = websiteName.at(-1);
            if (last == "/") {
                websiteName = websiteName.slice(0, -1)
            }

            userData.userLinks.push({
                'class': 'ni-globe',
                'title': websiteName,
                'path': res.data.user.websiteUrl
            })
        }
        if (res.data.user.instagramUrl != null && res.data.user.instagramUrl.length > 0) {
            userData.userLinks.push({
                class: 'ni-instagram',
                title: 'Instagram',
                path: res.data.user.instagramUrl
            })
        }
        if (res.data.user.twitterUrl != null && res.data.user.twitterUrl.length > 0) {
            userData.userLinks.push({
                class: 'ni-twitter',
                title: 'Twitter',
                path: res.data.user.twitterUrl
            })
        }
        if (res.data.user.facebookUrl != null && res.data.user.facebookUrl.length > 0) {
            userData.userLinks.push({
                class: 'ni-facebook-f',
                title: 'Facebook',
                path: res.data.user.facebookUrl
            })
        }
            // modelData.name = res.data.dreamBoothModel.name;
            // modelData.classPrompt = res.data.dreamBoothModel.classPrompt;
            // modelData.createdOnDate = res.data.dreamBoothModel.createdOnDate;
            // modelData.instancePrompt = res.data.dreamBoothModel.instancePrompt;
            // modelData.maxTrainSteps = res.data.dreamBoothModel.maxTrainSteps;
            // modelData.replicateModel = res.data.dreamBoothModel.replicateModel;
            // modelData.trainingImageCount = res.data.dreamBoothModel.trainingImageCount;
            // modelData.views = res.data.dreamBoothModel.views;
            // modelData.replicateModel = res.data.dreamBoothModel.replicateModel;
            // modelData.id = res.data.dreamBoothModel._id
        });

        store.dispatch("model/getUserProfilePosts", route.params.username)
        .then((res) => {
            userData.posts = res.data.posts;
        });
    })();
    return {
        userData
    }
  },
  methods: {
    async deletePost() {
      await this.$store.dispatch("model/deleteUserProfilePost", this.postToDelete)
        .then((res) => {            
            this.$store.dispatch("model/getUserProfilePosts", this.userData.username)
            .then((res) => {
                this.toastTitleText = "Post Deleted"
                this.toastBodyText = "Post successfully deleted!"
                this.notificationToast.show();
                this.userData.posts = res.data.posts;
            });
        });
    },
  },
  async mounted () {
    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function (toastEl) {
      return new Toast(toastEl, {animation: true, autohide: true, delay: 4000});
    })
    var myToastEl = document.getElementById('notificationToast');
    var myToast = Toast.getInstance(myToastEl);
    this.notificationToast = myToast;
  }
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .btn,
 .author-link,
 .card-price-wrap {
   z-index: 2;
   position: relative;
 }
</style>