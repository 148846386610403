<template>
<div>
    <div class="sidebar mb-0 mb-xl-0 pt-2 bg-gray border rounded row mb-3">
        <div class="col-md-6 col-lg-6 col-xl-12 sidebar-widget">
            <h3 class="mb-3">Folders</h3>
            <router-link :to="'/create-set'" v-if="true"><button class="btn btn-outline-primary btn-sm mb-3 ps-1">Add Folder</button></router-link>
            <!-- <a class="btn btn-outline-primary mb-3" v-if="modelCredits < 1" data-bs-toggle="modal" data-bs-target="#addCreditsModal">Create New Model</a> -->
            <p class="sidebar-text mb-3" v-if="materialSets.length < 1">Folders can be used to organize your quizzes, tests, and other materials.</p>
            <div class="row" v-if="materialSets.length == 0">
                        <div class="col-md-12">
                            <div class="alert alert-secondary mt-3" :style="'text-align: left'" role="alert">
                                <h5>You don't have any folders yet!</h5>
                                <p class="pt-2">Once you make a folder it will appear here.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row p-3 pt-0" id="folderContainer" v-if="materialSets.length > 0">
                        <div class="col-md-12 p-2 ps-0" v-for="set in materialSets" :key="set._id">
                            <div class="card card-full border border-primary">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 pt-1">
                                            <p class="text-small text-truncate text-black m-0 p-0">{{ set.name  }}</p>
                                        </div>

                                    </div>
                                    <!-- <div class="card-author mb-1 d-flex align-items-center">
                                        <span class="me-1 card-author-by">By</span>
                                        <div class="custom-tooltip-wrap">
                                            <router-link :to="'/dashboard'" class="custom-tooltip author-link">{{ set.creatorUsername }}</router-link>
                                        </div>
                                    </div> -->
                                    <div class="card-price-wrap d-flex align-items-center justify-content-between mb-1">
                                        <!-- <div class="me-2">
                                            <span class="card-price-title">Base Model</span>
                                            <span class="card-price-number">{{ model.baseModelFriendlyName }}</span>
                                        </div>
                                        <div class="me-2">
                                            <span class="card-price-title">Training Steps</span>
                                            <span class="card-price-number">{{ model.maxTrainSteps }}</span>
                                        </div>
                                        <div class="text-sm-end">
                                            <span class="card-price-title">Trained Using</span>
                                            <span class="card-price-number">{{ model.trainingImageCount }} images</span>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                        <router-link :to="'/set/' + set._id"><button class="btn btn-sm bg-primary text-white w-100">Open</button></router-link>
                                    </div>
                                    <div class="col-2 ps-0">
                                            <!-- <button type="button" class="btn m-0 p-0 text-danger" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="setToDelete=set">
                                                <em class="ni ni-trash"></em>
                                            </button> -->
                                            <!-- Default dropend button -->
                                            <!-- <div class="btn-group dropend border">
                                                <button type="" class="btn btn-sm m-0 p-0 rounded-5 pt-1 px-1 " data-bs-toggle="dropdown" aria-expanded="false">
                                                    <em class="ni ni-setting"></em>
                                                </button>
                                                <ul class="dropdown-menu mx-2">
                                                    <li>
                                                        <button type="button" class="text-danger dropdown-item small" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="modelToDelete=model">
                                                            Delete <em class="ni ni-trash"></em>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div> -->

                                            <button type="button" class="text-danger dropdown-item small" data-bs-toggle="modal" data-bs-target="#deleteMaterialSetModal" @click="materialSetToDelete=set">
                                                <em class="ni ni-trash"></em>
                                            </button>
                                        </div>
                                    </div>

                                    <!-- <div class="col-md-12" v-if="model.finishedTraining == false">
                                        <button class="btn btn-sm bg-primary w-100 text-white" data-bs-toggle="modal" data-bs-target="" disabled>Training in progress...</button>
                                        <p class="mt-2 text-black sidebar-text">This usually takes 15-30 minutes...</p>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
            <!-- <h3 class="mb-3">Info</h3>
            <p class="sidebar-text mb-3">{{ content }}</p>
            <p class="sidebar-text text-dark-gray">
                <span class="me-4"><strong class="text-black">{{ modelCredits }}</strong> Model Credits</span>
            </p>
            <p class="sidebar-text text-dark-gray mb-3">
                <span><strong class="text-black">{{ generationCredits }}</strong> Image Credits </span>
            </p> -->
            <!-- <button class="btn btn-sm btn-outline-primary mb-3">Add Credits</button> -->
            <!-- <a href="#" class="btn btn-sm btn-outline-primary mb-3" data-bs-toggle="modal" data-bs-target="#addCreditsModal">Buy Credits</a> -->

            <!-- <div class="follow-wrap mt-3">
                <p class="mb-1 text-black fw-semibold">{{ SectionData.authorSidebarData.sidebarWidget.followText }}</p>
                <div class="avatar-group mb-3">
                    <a :href="item.path" v-for="(item, i) in avatars" :key="i"><img :src="item.avatar" alt=""></a>
                </div>
                <a href="#" class="btn-link sidebar-btn-link" data-bs-toggle="modal" data-bs-target="#followersModal">{{ SectionData.authorSidebarData.sidebarWidget.btnText  }}</a>
            </div> -->
        </div>
        <!-- <div class="col-md-6 col-lg-6 col-xl-12 sidebar-widget">
            <h3 class="mb-3">{{ SectionData.authorSidebarData.sidebarWidgetTwo.title }}</h3>
            <ul class="social-links">
                <li v-for="(link, i) in links" :key="i"><router-link :to="link.path"><span class="ni icon" :class="link.class"></span>{{ link.title }}</router-link></li>
            </ul>
        </div> -->
     </div>
     <div class="modal fade" id="deleteMaterialSetModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-body text-center">
                      <img :src="SectionData.deleteModal.img" alt="" class="mb-3">
                      <h4 class="modal-tilte mb-2">Are you sure?</h4>
                      <p class="modal-text">Are you sure you want to permanently delete this folder?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm btn-default" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal" @click="deleteMaterialSet()">Delete</button>
                  </div>
              </div>
          </div>
      </div>
    <!-- Modal -->
    <div class="modal fade" id="addCreditsModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <ul class="nav nav-tabs nav-tabs-s1 nav-tabs--s1" id="myTabTwo" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="following-tab" data-bs-toggle="tab" data-bs-target="#following" type="button" role="tab" aria-controls="following" aria-selected="true">Buy Credits</button>
                        </li>
                        <!-- <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="followers-tab" data-bs-toggle="tab" data-bs-target="#followers" type="button" role="tab" aria-controls="followers" aria-selected="true">{{ SectionData.followersModal.btnTextTwo }}</button>
                        </li> -->
                    </ul>
                    <button type="button" class="btn-close icon-btn" data-bs-dismiss="modal" aria-label="Close">
                        <em class="ni ni-cross"></em>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="tab-content" id="myTabContentTwo">
                        <div class="tab-pane fade show active" id="following" role="tabpanel" aria-labelledby="following-tab">
                            <div class="item-detail-tab-wrap">
                                <div class="card-follow d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">

                                        <div class="flex-grow-1">
                                            <p class="fw-semibold fs-14 lh"><span class="text-black">Model Training Credit - $10.00</span></p>
                                            <span class="d-block fw-small fs-15">Each model training credit purchase includes 100 image generation credits.</span>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-md btn-primary btn-show m-2" @click="createCreditCheckoutSession('modelTrainingCredit')">Checkout</button>
                                    <!-- <button type="button" class="btn btn-sm btn-light btn-hide"><em class="ni ni-user-add"></em></button> -->
                                </div>
                                <div class="card-follow d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">

                                        <div class="flex-grow-1">
                                            <p class="fw-semibold fs-14 lh"><span class="text-black">Image Generation Credits  - $5.00</span></p>
                                            <span class="d-block fw-small fs-15">100 image generation credits for use with any model.</span>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-md btn-primary btn-show m-2" @click="createCreditCheckoutSession('imageGenerationCredits')">Checkout</button>
                                    <!-- <button type="button" class="btn btn-sm btn-light btn-hide"><em class="ni ni-user-add"></em></button> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade show active" id="followers" role="tabpanel" aria-labelledby="followers-tab">
                            <div class="item-detail-tab-wrap">
                                <div class="card-follow d-flex align-items-center justify-content-between" v-for="item in SectionData.followersModal.followerList" :key="item.id">
                                    <div class="d-flex align-items-center">
                                        <a :href="item.path" class="avatar flex-shrink-0 me-2">
                                            <img :src="item.avatar" alt="avatar">
                                        </a>
                                        <div class="flex-grow-1">
                                            <p class="fw-semibold fs-14 lh"><a :href="item.path" class="text-black">{{ item.title }}</a></p>
                                            <span class="d-block fw-medium fs-15">{{ item.userName }}</span>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-sm btn-light btn-show">{{ item.btnText }}</button>
                                    <button type="button" class="btn btn-sm btn-light btn-hide"><em class="ni ni-user-add"></em></button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<style type="scss">
    #folderContainer {
        max-height: 350px;
        overflow: scroll;
    }
</style>
<script>

// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
let stripe;
const stripeJs = document.createElement("script");
stripeJs.src = "https://js.stripe.com/v3/";
stripeJs.async = true;
stripeJs.onload = () => {
  setTimeout(() => {
    stripe = window.Stripe("pk_live_51LfWOXJinHl0TVFJBQnvpDizYtkKSFYxj0n15cNWodd8Mj6PpMl9cJFfpoTt5cYMuBLzvRcKU29NWKVVyjX0GIRd00BZh2du7i");
    // stripe = window.Stripe("pk_test_51LfWOXJinHl0TVFJQupMFkHoQtFsddVzKMh3iERrulSIkBPzsO3lQnKTLYtjCx09CmtqYgH1ClB19zAcuEera8Ep00pl1P0OrY");
  }, 500);
};
document.body && document.body.appendChild(stripeJs);
export default {
  name: 'DashboardSidebar',
  props: ['generationCredits', 'modelCredits', 'datetext'],
  data () {
    return {
      SectionData,
      materialSets: [],
      materialSetToDelete: null
    }
  },
  mounted () {
    this.$store
        .dispatch("material/getUsersMaterialSets")
        .catch((err) => {
            console.log(err);
            this.errorMessage = err.message;
            this.isError = true;
        })
        .then((res) => {
            for (let i = 0; i < res.data.materialSets.data.length; i++) {
                this.materialSets.push(res.data.materialSets.data[i]);
            }
            this.materialSetsCount = res.data.materialSets.count;
        })
  },
  methods: {
    createCreditCheckoutSession(product) {
      this.$store
        .dispatch("auth/createCreditCheckoutSession", { referral: this.$referral, product:  product})
        .then(function (response) {
          stripe.redirectToCheckout({
            sessionId: response.id
          })
        })
        .catch(function (error) {
          console.error("Error:", error);
      });
    }, 
    async deleteMaterialSet() {
        await this.$store.dispatch("material/deleteMaterialSet", this.materialSetToDelete)
        .then((res) => {
            this.$store
            .dispatch("material/getUsersMaterialSets")
            .catch((err) => {
                console.log(err);
                this.errorMessage = err.message;
                this.isError = true;
            })
            .then((res) => {
                this.materialSets = [];
                for (let i = 0; i < res.data.materialSets.data.length; i++) {
                    this.materialSets.push(res.data.materialSets.data[i]);
                }
                this.materialSetsCount = res.data.materialSets.count;
            })
        });
    },
  }
}
</script>
