<template>
    <div class="card card-full card-s3">
        <div class="card-body h-100 pt-0 mt-0 px-0 pb-0">
            <h5 class="card-title mb-4">{{item.title}}</h5>
            
        <div class="card-image">
            <img v-lazy="item.formats['image/jpeg']" class="card-img w-75 mb-2" :alt="item.title">
        </div>
            <div class="card-author mb-0 d-flex align-items-center position-absolute bottom-0 start-10">
                <span class="me-1 card-author-by ">{{ item.authors.length > 0 ? "" + formatName(item.authors[0].name) : " "}}</span>
                <!-- <div class="custom-tooltip-wrap">
                    <router-link :to="product.authorLink" class="custom-tooltip author-link">@username</router-link>
                    <div class="card-generic custom-tooltip-dropdown">
                        <div class="author-action d-flex flex-wrap align-items-center mb-3">
                            <div class="flex-shrink-0 avatar">
                                <img :src="product.avatar" alt="avatar">
                            </div>
                            <div class="ms-2">
                                <span class="author-username">{{ product.userName }}</span>
                                <span class="author-follow-text">{{ product.followersText }}</span>
                            </div>
                        </div>
                        <h6 class="author-name mb-1">{{ product.authorName }}</h6>
                        <p class="author-desc smaller mb-3">{{ product.desc }}</p>
                        <div class="follow-wrap mb-3">
                            <h6 class="mb-1 smaller text-uppercase">Followed by</h6>
                            <div class="avatar-group">
                                <router-link :to="avatar.path" v-for="avatar in product.avatars" :key="avatar.id">
                                    <img :src="avatar.img" alt="avatar">
                                </router-link>
                            </div>
                        </div>
                        <router-link :to="product.authorLink" class="btn btn-sm bg-dark-dim">Follow</router-link>
                    </div>
                </div> -->
            </div><!-- end card-author -->
            <!-- <div class="card-price-wrap d-flex align-items-center justify-content-between position-relative fixed-bottom">
                <div class="me-5 me-sm-2">
                    <span class="card-price-title">Current bid</span>
                    <span class="card-price-number">{{ item.languages[0] }} ETH <span class="equivalance">{{ item.title }} USD</span></span>
                </div>
                <span class="btn btn-sm bg-dark-dim w-100">Bid</span>
            </div> -->
        </div><!-- end card-body -->
        <router-link
            class="details"
            :to="'/book/' + item.url "
        >
        </router-link>
    </div><!-- end card -->
</template>
<script>
import { createPopper } from '@popperjs/core';
export default {
  name: 'ProductsThree',
  props: ['item'],
  mounted () {

    /*============= Custom Tooltips =============== */
    function customTooltip(selector, active) {
    let elem = document.querySelectorAll(selector);
        if(elem.length > 0){
            elem.forEach(item => {
                const parent = item.parentElement;
                const next = item.nextElementSibling;
                createPopper(item, next);
                parent.addEventListener("mouseenter", function() {
                    parent.classList.add(active)
                });
                parent.addEventListener("mouseleave", function() {
                    parent.classList.remove(active)
                });
            });
        }
    }

    customTooltip('.custom-tooltip','active');

  },
  methods: {
    formatName(name) {
        // Split the name into an array using the comma as a delimiter
        var nameArray = name.replace(/\([^()]*\)/g, '').split(", ");

        // Extract the first and last name from the array
        var lastName = nameArray[0];
        var firstName = nameArray[1];

        
        if (lastName && firstName) {
            // Return the formatted name
            return firstName + " " + lastName;   
        } else {
            return name;
        }
    }
  }
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .author-link {
   z-index: 2;
   position: relative;
 }
</style>