<template>
  <nav class="header-menu menu nav">
      <!-- menu list -->
      <MenuList></MenuList>
      <!-- header btn -->
      <ul class="menu-btns">
          <!-- <li><ButtonLink :text="'Login'" link="/login" classname="btn" :class="'btn-light'"></ButtonLink></li> -->
          <li><router-link :to="'/login'"><button class="btn btn-outline-primary btn-md w-100">Login</button></router-link></li>
          <li><router-link :to="'/register'"><button  class="btn btn-primary w-100">Create Account</button></router-link></li>
          <!-- <li>
             <ThemeSwitcher></ThemeSwitcher>
          </li> -->
      </ul>
      <!-- <MenuTwo classname="btn-primary"></MenuTwo> -->

  </nav><!-- .header-menu -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import { mapGetters } from "vuex";
// import MenuTwo from '@/components/common/MenuTwo.vue'

// @ is an alias to /src
import MenuList from '@/components/common/MenuList.vue'

export default {
  name: 'Menu',
  props: ['classname'],
  components: {
    MenuList,
    // MenuTwo
  },
  data () {
    return {
      SectionData
    }
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    isLocationResolved() {
      return this.$store.state.meta.isLocationResolved;
    },
    ...mapGetters({
      user: "auth/authUser",
    }),
  },
  mounted() {
  }
}
</script>
