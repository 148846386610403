<template>
  <section class="explore-section">
      <div class="container">
          <div class="filter-box d-flex flex-wrap align-items-center justify-content-between pb-2 pb-sm-4">
              <div class="input-group mb-3">
                <input v-model="searchTerm" type="search" class="form-control form-control-s1" placeholder="Your search term..." v-on:keyup.enter="search()">
                <div class="input-group-append">
                  <button class="btn btn-primary rounded-right" type="button" @click="search()">Search</button>
                </div>
              </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <div class="row mb-3">
                <div class="btn-group w-100">
                  <a href="#" class="btn btn-sm btn-outline-primary" :class="tab.class" v-for="tab in filterMenu" @click.prevent="setTab(tab, tab.id)"  :key="tab.id">{{ tab.title }}</a>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 d-flex"> 
                  <span class="p-2 border rounded w-100 bg-primary" data-bs-toggle="collapse" href="#advancedOption" role="button" aria-expanded="true" aria-controls="advancedOption" @click="updateCategoriesText()">
                    <a class="link-primary text-white small text-decoration-underline">
                        {{ this.categoriesControlText }}
                    </a>
                  </span> 
                </div>  
              </div>  
              <div class="row">
                <div class="collapse show" id="advancedOption">
                  <div class="mb-3">
                    <div class="mt-2 list-group-item">
                        <router-link v-if="this.subject == null" :to='"/books"' class=""> <span class="p-1 mx-1 h-100 linkCard card border align-bottom bg-primary text-white">All Categories</span></router-link>
                        <router-link v-else :to='"/books"' class=""> <span class="p-1 mx-1 h-100 linkCard card border align-bottom">All Categories</span></router-link>
                    </div>
                    <div class="mt-2 list-group-item" v-for="(subject, i) in generalSubjects" :key="i">
                        <router-link v-if="subject.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-') == this.subject" :to='"/books/" + subject.toLowerCase().replaceAll("", "").replace(/[^a-z0-9]+/g, "-")' class=""> <span class="p-1 mx-1 h-100 linkCard card border align-bottom bg-primary text-white">{{ subject }}</span></router-link>
                        <router-link v-else :to='"/books/" + subject.toLowerCase().replaceAll("", "").replace(/[^a-z0-9]+/g, "-")' class=""> <span class="p-1 mx-1 h-100 linkCard card border align-bottom bg-light">{{ subject }}</span></router-link>

                    </div>
                  </div>
                </div>   
              </div>  
              
            </div>
            <div class="col-lg-9">
              <div class="row g-gs mb-4">
                <div class="col-xl-3 col-lg-4 col-sm-6" v-for="item in items" :key="item.id">
                    <BookDirectoryItem v-if:="item.title" :item="item"></BookDirectoryItem>
                </div>
              </div>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import BookDirectoryItem from '@/components/section/BookDirectoryItem'
import { useRoute } from "vue-router";

export default {
name: 'BookDirectorySection',
components: { 
  BookDirectoryItem
},
data () {
  return {
    SectionData,
    subject: this.$route.params.subject,
    items: null,
    searchTerm: null,
    categoriesControlText: "Hide Categories",
    generalSubjects: [
      "Classic Literature",
      "Science Fiction",
      "Fantasy",
      "Romance",
      "Historical Fiction",
      "Mystery & Detective Stories",
      "Poetry",
      "Adventure",
      "Horror",
      "Biographies & Autobiographies",
      "Children's Literature",
      "Drama",
      "Mythology",
      "Westerns",
      "War & Military",
      "Religion",
      "Philosophical literature",
      "Educational literature",
      "Non-fiction",
      "History",
      "Cultural studies",
      "Political Analysis",
      "Comedy",
      "Short Stories",
      "Science & Natural History",
      "Travelogues",
      "Art",
      "Music",
      "Cooking & Cuisine",
      "Health & Fitness",
      "Economics",
      "Literary Criticism",
      "Linguistics",
      "Technology & Engineering",
      "Mathematics",
      "Psychology & Psychiatry",
      "Philosophy",
      "Legal Studies",
      "Sociology",
      "Anthropology",
      "Sports & Recreation",
      "Theater",
      "Astronomy",
      "Agriculture",
      "Archaeology",
      "Biology",
      "Chemistry",
      "Physics",
      "Self-help",
      "Sustainable Living"
    ],
    filterMenu: [
    {
          id: 1,
          title: 'Random',
          sort: 'random',
          class: '',
          options: [
              {
                  category: 'art'
              },
              {
                  category: 'music'
              },
              {
                  category: 'collectibles'
              },
              {
                  category: 'games'
              },
          ]
      },
      {
          id: 2,
          title: 'Popular',
          sort: 'download_count',
          class: 'active',
          options: [
              {
                  category: 'art'
              }
          ]
      },
      {
          id: 3,
          title: 'Recent',
          sort: 'createdAt',
          class: '',
          options: [
              {
                  category: 'art'
              },
              {
                  category: 'music'
              },
              {
                  category: 'collectibles'
              },
              {
                  category: 'games'
              },
          ]
      }
    ],
    selectedTab:{
          id: 1,
          title: 'Popular',
          sort: 'download_count',
          class: 'active',
          options: [
              {
                  category: 'art'
              },
              {
                  category: 'music'
              },
              {
                  category: 'collectibles'
              },
              {
                  category: 'games'
              },
          ]
      },
    previous_active_id: 2
  }
},
methods: {
  setTab(tab, id) {
    this.selectedTab = tab;
    if (this.previous_active_id === id) return;
    this.filterMenu.find(menu => menu.id === this.previous_active_id).class = '';
    this.filterMenu.find(menu => menu.id === id).class = 'active btn-primary';
    const data = {
        sort: tab.sort,
        searchTerm: this.searchTerm,
        subject: this.subject
      }
      this.$store.dispatch("api/getGutenbergBooks", data)
      .then((res) => {
        this.items = res.data;
        this.isLoading = false;
        this.prompt = res.data.prompt;
        this.image = res.data.image;
        this.productIds = res.data.productIds;
        this.productImages = res.data.productImages;
        this.createdOnDate = res.data.createdOnDate;
        this.views = res.data.views;
      });
    this.previous_active_id = id;
  },
  search() {
    const data = {
        sort: this.selectedTab.sort,
        searchTerm: this.searchTerm,
        subject: this.subject
      }
      this.$store.dispatch("api/getGutenbergBooks", data)
      .then((res) => {
        this.items = res.data;
        this.isLoading = false;
        this.prompt = res.data.prompt;
        this.image = res.data.image;
        this.productIds = res.data.productIds;
        this.productImages = res.data.productImages;
        this.createdOnDate = res.data.createdOnDate;
        this.views = res.data.views;
      });
  },
  updateCategoriesText() {
    if (this.categoriesControlText == "Show Categories") {
      this.categoriesControlText = "Hide Categories";
    } else {
      this.categoriesControlText = "Show Categories";
    }
  },
  getSubjectFriendlyName () {
    const subjectFriendlyName = this.generalSubjects.find((e) => e.toLowerCase().replaceAll("", "").replace(/[^a-z0-9]+/g, "-") == this.subject);
    return subjectFriendlyName;
  }
},
computed: {
    filteredData () {
        return this.items.filter(data => {
        if (this.selectedTab === null) return true
        const opts = this.selectedTab.options.map(opt => opt.category)
        return opts.includes(data.category)
      })
    }
},
mounted() {
},
beforeMount() {
  let searchParams = new URLSearchParams(window.location.search)
  let query = "";
  if(searchParams.has('query')) {
    query = searchParams.get('query')
    this.searchTerm = query;
  }
  const data = {
      sort: "download_count",
      searchTerm: query,
      subject: this.subject
  }
  this.$store.dispatch("api/getGutenbergBooks", data)
  .then((res) => {
    this.items = res.data;
    this.isLoading = false;
    this.prompt = res.data.prompt;
    this.image = res.data.image;
    this.productIds = res.data.productIds;
    this.productImages = res.data.productImages;
    this.createdOnDate = res.data.createdOnDate;
    this.views = res.data.views;
  });
}

}
</script>

<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.author-link {
 z-index: 2;
 position: relative;
}
.list-group-item:hover {
  text-decoration: underline;
}
</style>
