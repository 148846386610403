<template>
     <div class="sidebar row">
        <div class="col-lg-12 sidebar-widget">
            <div class="card">
                <div class="card-body card-body-s1">
                    <h4 class="mb-3">Search</h4>
                    <form v-on:submit.prevent="">
                        <div class="input-group">
                            <input v-model="searchTerm" type="text" class="form-control form-control-s1" :placeholder="'Search our posts...'" v-on:keyup.enter="search()">
                            <button type="button" class="input-group-text"><em class="ni ni-search" @click="search"></em></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="post.authorFirstName && post.authorBio" class="col-md-6 col-lg-12 sidebar-widget">
            <div class="card">
                <div class="card-body card-body-s1">
                    <div v-if="post.authorProfilePicture" class="avatar avatar-1 mb-3">
                        <img :src="post.authorProfilePicture" :alt="post.authorFirstName + ' ' + post.authorLastName + ' picture'">
                    </div>
                    <h4 class="mb-2">{{ post.authorFirstName + ' ' + post.authorLastName }}</h4>
                    <p class="sidebar-text mb-3">{{ post.authorBio }}</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-12 sidebar-widget" v-if="post.categories && post.categories.length > 0">
            <div class="card">
                <div class="card-body card-body-s1">
                    <h4 class="mb-3">Categories</h4>
                    <ul class="list-item">
                        <li v-for="list in post.categories" :key="list"><router-link :to="'/blog/category/'+list.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-')"><em class="ni ni-chevron-right me-1"></em>{{ list }}</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-12 sidebar-widget" v-if="relatedPosts && relatedPosts.length > 0">
            <div class="card">
                <div class="card-body card-body-s1">
                    <h4 class="mb-3">Recommended</h4>
                    <ul class="posts">
                        <li class="d-flex align-items-center" v-for="item in relatedPosts" :key="item.id">
                            <router-link v-if="item.thumbnailImage && item.thumbnailImage.length > 0" :to="'/blog/' + item.url" class="thumbnail flex-shrink-0 me-3">
                                <img :src="item.thumbnailImage" :alt="relatedPosts.title + ' thumbnail'">
                            </router-link>
                            <div class="flex-grow-1">
                                <h6 class="posts-title mb-1"><router-link :to="'/blog/' + item.url">{{ item.title }}</router-link></h6>
                                <p class="posts-meta">{{ item.publishDate }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-12 sidebar-widget" v-if="post.tags && post.tags.length > 0">
            <div class="card">
                <div class="card-body card-body-s1">
                    <h4 class="mb-3">Tags</h4>
                    <ul class="tag-list">
                        <li v-for="tag in post.tags" :key="tag"><router-link :to="'/blog/tag/'+tag.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-')" class="tag-link">{{ tag }}</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'Sidebar',
  props: {
    post: Object,
    relatedPosts: [Object]
  },
  data () {
    return {
      SectionData,
      searchTerm: '',
    }
  },
  methods: {
    search () {
        this.$router.push({ path: '/blog', query: { searchTerm: this.searchTerm}});
    }
  }
}
</script>
