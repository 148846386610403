// import * as Vue from 'vue'
import axios from 'axios'
import { rejectError } from '@/helpers'
import axiosInstance from '@/services/axios'


export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {

  },
  actions: {
    generate ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.post('/api/v1/generate', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    startGeneration ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.post('/api/v1/startgeneration', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    checkGeneration ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.post('/api/v1/checkgeneration', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getGeneration ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.get('/api/v1/getgeneration?url=' + params.url)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getGenerations ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      let url = '/api/v1/getgenerations?sort=' + params.sort;
      if (params.searchTerm) {
        url += '&searchTerm=' + params.searchTerm
      }
      return axios.get(url)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getProducts ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.get('/api/v1/getproducts?sort=' + params.sort)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getProductsForGeneration ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.get('/api/v1/getproductsforgeneration?id=' + params.id)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getFeaturedProducts ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.get('/api/v1/getfeaturedproducts')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getProductByGenerationUrlAndTitle ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.get('/api/v1/getproductbygenerationurlandtitle?url=' + params.url + "&title=" + params.title)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    deleteGeneration ({rootState}, params) {
      console.log(rootState)
      if (rootState.auth && rootState.auth.user) {
        params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/deletegeneration', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },


    getGutenbergBook ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.get('/api/v1/getgutenbergbook?url=' + params.url)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getGutenbergBooks ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      let url = '/api/v1/getgutenbergbooks?sort=' + params.sort;
      if (params.searchTerm) {
        url += '&searchTerm=' + params.searchTerm
      }
      if (params.limit) {
        url += '&limit=' + params.limit
      }
      if (params.subject) {
        url += '&subject=' + params.subject
      }
      return axios.get(url)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
  },
  mutations: {
  }
}