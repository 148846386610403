import { createWebHistory, createRouter } from "vue-router";
import store from '@/store'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('../pages/Home-v7.vue'),
  },
  {
    path:'/generate',
    name:'Generate',
    component: () => import('../pages/Generate.vue'),
    props: true
  },
  {
    path:'/thankyou',
    name:'ThankYou',
    component: () => import('../pages/ThankYou.vue'),
    props: true
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../pages/Register.vue')
  },
  {
    path: '/reset',
    name: 'Reset Password',
    component: () => import('../pages/ResetPassword.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    component: () => import('../pages/ForgotPassword.vue')
  },
  // {
  //   path: '/explore',
  //   name: 'explore',
  //   component: () => import('../pages/Explore-v6.vue')
  // },
  {
    path: '/books',
    name: 'books',
    component: () => import('../pages/BookDirectory.vue')
  },
  {
    path: '/books/:subject',
    name: 'subjects',
    component: () => import('../pages/BookDirectory.vue')
  },
  {
    path:'/book/:url',
    name:'GutenbergBookDetail',
    component: () => import('../pages/GutenbergBookDetail.vue')
  },

  {
    path:'/book/:url/:chapterUrl',
    name:'GutenbergBookChapterDetail',
    component: () => import('../pages/GutenbergBookChapterDetail.vue')
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: () => import('../pages/Subscription.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue')
  },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: () => import('../pages/Blog.vue')
  // },
  // {
  //   path: '/blog/:url',
  //   name: 'blog',
  //   component: () => import('../pages/BlogDetail.vue')
  // },
  {
    path: '/material-generator',
    name: 'materialgenerator',
    component: () => import('../pages/MaterialGenerator.vue'),
    meta: { onlyAuthUser: true }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../pages/Account.vue'),
    meta: { onlyAuthUser: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../pages/Dashboard.vue'),
    meta: { onlyAuthUser: true }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../pages/Account.vue'),
    meta: { onlyAuthUser: true }
  },
  // {
  //   path: '/home-v2',
  //   name: 'Home 2',
  //   component: () => import('../pages/Home-v2.vue'),
  // },
  // {
  //   path: '/home-v3',
  //   name: 'Home 3',
  //   component: () => import('../pages/Home-v3.vue')
  // },
  // {
  //   path: '/home-v4',
  //   name: 'Home 4',
  //   component: () => import('../pages/Home-v4.vue')
  // },
  // {
  //   path: '/home-v5',
  //   name: 'Home 5',
  //   component: () => import('../pages/Home-v5.vue')
  // },
  // {
  //   path: '/home-v6',
  //   name: 'Home 6',
  //   component: () => import('../pages/Home-v6.vue')
  // },
  // {
  //   path: '/home-v7',
  //   name: 'Home 7',
  //   component: () => import('../pages/Home-v7.vue')
  // },

  // {
  //   path:'/g/:id',
  //   name:'GenerationDetail',
  //   component: () => import('../pages/GenerationDetail.vue')
  // },
  // {
  //   path:'/:username/:url',
  //   name:'DreamBoothPredictionDetail',
  //   component: () => import('../pages/DreamBoothPredictionDetail.vue')
  // },
  // {
  //   path:'/:username/:predictionurl/:title',
  //   name:'DreamBoothProductDetail',
  //   component: () => import('../pages/DreamBoothProductDetail.vue')
  // },
  // {
  //   path:'/g/:generationurl/:title',
  //   name:'GenerationProductDetail',
  //   component: () => import('../pages/GenerationProductDetail.vue'),
  //   props: true
  // },
  // {
  //   path:'/product-details-v1-:id',
  //   name:'ProductDetail',
  //   component: () => import('../pages/ProductDetail.vue'),
  //   props: true
  // },
  // {
  //   path:'/product-details-v2-:id',
  //   name:'ProductDetail_v2',
  //   component: () => import('../pages/ProductDetail-v2.vue'),
  //   props: true
  // },
  // {
  //   path:'/product-details-v3-:id',
  //   name:'ProductDetail_v3',
  //   component: () => import('../pages/ProductDetail-v3.vue'),
  //   props: true
  // },
  // {
  //   path: '/item-details',
  //   name: 'itemDetails',
  //   component: () => import('../pages/ItemDetails.vue')
  // },
  // {
  //   path: '/item-details-v2',
  //   name: 'itemDetailsV2',
  //   component: () => import('../pages/ItemDetails-v2.vue')
  // },
  // {
  //   path: '/products',
  //   name: 'Products',
  //   component: () => import('../pages/Products.vue')
  // },
  // {
  //   path: '/explore-v2',
  //   name: 'exploreV2',
  //   component: () => import('../pages/Explore-v2.vue')
  // },
  // {
  //   path: '/explore-v3',
  //   name: 'exploreV3',
  //   component: () => import('../pages/Explore-v3.vue')
  // },
  // {
  //   path: '/explore-v4',
  //   name: 'exploreV4',
  //   component: () => import('../pages/Explore-v4.vue')
  // },
  // {
  //   path: '/explore-v5',
  //   name: 'exploreV5',
  //   component: () => import('../pages/Explore-v5.vue')
  // },
  // {
  //   path: '/explore-v6',
  //   name: 'exploreV6',
  //   component: () => import('../pages/Explore-v6.vue')
  // },
  // {
  //   path: '/author',
  //   name: 'author',
  //   component: () => import('../pages/Author.vue')
  // },
  // {
  //   path: '/about-us',
  //   name: 'about-us',
  //   component: () => import('../pages/AboutUs.vue')
  // },
  // {
  //   path: '/activity',
  //   name: 'activity',
  //   component: () => import('../pages/Activity.vue')
  // },
  // {
  //   path: '/ranking',
  //   name: 'ranking',
  //   component: () => import('../pages/Ranking.vue')
  // },
  // {
  //   path: '/wallet',
  //   name: 'wallet',
  //   component: () => import('../pages/Wallet.vue')
  // },
  // {
  //   path: '/wallet-v2',
  //   name: 'wallet-v2',
  //   component: () => import('../pages/WalletTwo.vue')
  // },
  // {
  //   path: '/blog/category',
  //   name:'blog',
  //   component: () => import('../pages/Blog.vue'),
  // },
  // {
  //   path: '/blog/tag',
  //   name:'blogTaf',
  //   component: () => import('../pages/Blog.vue'),
  // },
  {
    path: '/blog/category/:category',
    name:'blogCategory',
    component: () => import('../pages/Blog.vue'),
  },
  {
    path: '/blog/tag/:tag',
    name:'blogTag',
    component: () => import('../pages/Blog.vue'),
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../pages/Blog.vue')
  },
  {
    path: '/blog/:url',
    name:'BlogDetail',
    component: () => import('../pages/BlogDetail.vue'),
  },
  //   path: '/blog-detail',
  //   name: 'blog-details',
  //   component: () => import('../pages/BlogDetail.vue')
  // },
  // {
  //   path: '/login-v2',
  //   name: 'login v2',
  //   component: () => import('../pages/Login-v2.vue')
  // },
  // {
  //   path: '/offers',
  //   name: 'Offer',
  //   component: () => import('../pages/Offers.vue')
  // },
  // {
  //   path: '/activity-v2',
  //   name: 'activity-v2',
  //   component: () => import('../pages/Activity-v2.vue')
  // },
  // {
  //   path: '/purchases-sales',
  //   name: 'purchases-sales',
  //   component: () => import('../pages/PurchasesSales.vue')
  // },
  // {
  //   path: '/transactions',
  //   name: 'transactions',
  //   component: () => import('../pages/Transactions.vue')
  // },
  // {
  //   path: '/display',
  //   name: 'display',
  //   component: () => import('../pages/Display.vue')
  // },
  // {
  //   path: '/redeem',
  //   name: 'redeem',
  //   component: () => import('../pages/Redeem.vue')
  // },
  // {
  //   path: '/deposit-enfties',
  //   name: 'deposit-enfties',
  //   component: () => import('../pages/DepositEnfties.vue')
  // },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import('../pages/Profile.vue')
  // },
  // {
  //   path: '/:username',
  //   name: 'user-profile',
  //   component: () => import('../pages/UserProfile.vue')
  // },
  // {
  //   path: '/payment-methods',
  //   name: 'payment-methods',
  //   component: () => import('../pages/PaymentMethods.vue')
  // },
  // {
  //   path: '/seller-settings',
  //   name: 'seller-settings',
  //   component: () => import('../pages/SellerSettings.vue')
  // },
  // {
  //   path: '/notifications',
  //   name: 'notifications',
  //   component: () => import('../pages/Notifications.vue')
  // },
  // {
  //   path: '/security',
  //   name: 'security',
  //   component: () => import('../pages/Security.vue')
  // },
  // {
  //   path: '/create',
  //   name: 'create',
  //   component: () => import('../pages/Create.vue')
  // },
  // {
  //   path: '/create-single',
  //   name: 'create-single',
  //   component: () => import('../pages/CreateSingle.vue')
  // },
  // {
  //   path: '/create-multiple',
  //   name: 'create-multiple',
  //   component: () => import('../pages/CreateMultiple.vue')
  // },
  // {
  //   path: '/create-model',
  //   name: 'create-dreambooth-model',
  //   component: () => import('../pages/CreateDreamboothModel.vue'),
  //   meta: { onlyAuthUser: true }
  // },
  // {
  //   path: '/create-set',
  //   name: 'create-set',
  //   component: () => import('../pages/CreateSet.vue'),
  //   meta: { onlyAuthUser: true }
  // },
  // {
  //   path: '/dreambooth/:modelId',
  //   name: 'dreambooth-model-detail',
  //   component: () => import('../pages/DreamBoothModelDetail.vue'),
  //   meta: { onlyAuthUser: true }
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: () => import('../pages/Contact.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        left: 0,
        top: 0
      }
    }
  }
});


router.beforeEach((to, from, next) => {
  store.dispatch('auth/getAuthUser')
    .then(() => {
      const isAuthenticated = store.getters['auth/isAuthenticated']

      if (to.meta.onlyAuthUser) {
        if (isAuthenticated) {
          next()
        } else {
          next({name: 'login'})
        }
      } else if (to.meta.onlyGuestUser) {
        if (isAuthenticated) {
          next({name: 'Home'})
        } else {
          next()
        }
      } else {
        next()
      }
    })
})

export default router;