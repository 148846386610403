// import * as Vue from 'vue'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {
  },
  actions: {

    getNewChatAuthToken ({rootState}) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/chat/getnewchatauthtoken')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
  }
}