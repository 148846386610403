<template>
<section class="author-section mt-5 mb-5">
      <div class="container">
          <div class="row">
              <div class="col-xl-12 ps-xl-4">
                  <div class="author-items-wrap">
                    <h3>Create Material</h3>
                    <div class="mb-3"></div>
                    <div class="col-12 mb-3">
                    <form action="#" @keydown.enter.prevent="formSubmit" class="form-create mb-2 mb-lg-0">
                        <div class="row">
                            <div class="col-md-12 form-item ">
                                <label class="form-label">Name</label>
                                <p class="form-text"></p>
                                <input type="text" class="form-control form-control-sm" v-model="form.name" placeholder="e.g. Shakespeare Act 1 Quiz" :disabled="isDisabled && !isLoading">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mt-2 form-item ">
                                <label class="form-label">Subject</label>
                                <p class="form-text"></p>
                                <input type="text" class="form-control form-control-sm" v-model="form.subject" placeholder="e.g. ELA" :disabled="isDisabled && !isLoading">
                            </div>
                            <div class="col-md-4 mt-2 form-item ">
                                <label class="form-label">Grade Level</label>
                                <v-select class="generic-select" v-model="form.grade" :options="grades" :disabled="isDisabled && !isLoading"></v-select>
                                <!-- <input v-if="useCustomCKPT"  type="text" class="form-control form-control-sm" v-model="customCKPTUrl" placeholder="https://huggingface.co/prompthero/openjourney-v2/resolve/main/openjourney-v2.ckpt"> -->
                            </div>
                            <div class="col-md-4 mt-2 form-item ">
                                <label class="form-label">Material Type</label>
                                <v-select class="generic-select" v-model="form.type" :options="options" :disabled="isDisabled && !isLoading"></v-select>
                                <!-- <input v-if="useCustomCKPT"  type="text" class="form-control form-control-sm" v-model="customCKPTUrl" placeholder="https://huggingface.co/prompthero/openjourney-v2/resolve/main/openjourney-v2.ckpt"> -->
                            </div>
                        </div>
                        <div class="col-md-12 mt-2 form-item">
                            <label class="form-label mb-0">Reference Text</label>
                            <p class="form-text"><small>Paste any relevant information in this box and we will use it to generate the material with. Recommended but not required.</small></p>
                            <!-- <button class="btn btn-outline-success btn-sm mb-1" @click.prevent="getPromptSuggestion()" :style="'float: right'">Randomize Prompt</button> -->
                            <textarea  id="referenceTextInput" type="text" v-model="form.referenceText" class="form-control form-control-s1 input-sm" :style="'height: 75px'" :placeholder="referenceTextContent" :disabled="isDisabled && !isLoading"></textarea>
                        </div>
                        <div class="collapse" id="advancedOption">
                            <div class="col-md-12 mt-2 form-item">
                            <label class="form-label mb-0 d-inline-flex">Import Text from Image or Documents</label>
                            <div v-if="ocrLoading" id="ocrLoading" class="d-inline-flex">
                                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </div>

                            <p class="form-text"><small>Upload images or documents (PowerPoint, Word, etc.) containing text that you want to add to your reference text.</small></p>
                            <div class="file-upload-wrap">
                                <file-pond id="filepond" name="files" ref="pond" label-idle="<u>Click<u/> to upload or drop files here..." v-bind:allow-multiple="true" accepted-file-types="image/jpeg, image/png, image/jpg, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint" v-bind:credits="false" v-bind:files="textReferenceFiles" v-on:addfile="handleFileAdded" @addfilestart="addFileStartHandler($event)"/>
                            </div>
                        </div>
                        </div>
                        <p>
                            <a class="link-primary text-blue small" data-bs-toggle="collapse" href="#advancedOption" role="button" aria-expanded="false" aria-controls="advancedOption">
                                Import Text from Images or Documents
                            </a>
                        </p>
                        
                        <div class="col-md-12 mt-2 form-item">
                                <label class="form-label mb-0">Description</label>
                                <p class="form-text"><small>You can provide more details about what you want the material to be. Not required.</small></p>
                                <!-- <button class="btn btn-outline-success btn-sm mb-1" @click.prevent="getPromptSuggestion()" :style="'float: right'">Randomize Prompt</button> -->
                                <textarea  id="descriptionTextInput" type="text" v-model="form.descriptionText" class="form-control form-control-s1 input-sm" :style="'height: 75px'" :placeholder="descriptionTextContent" :disabled="isDisabled"></textarea>
                        </div>
                        <VueLoadingButton id="vueCreateModelLoadingButton" :loading="isLoading" :styled="isStyled" @click.prevent="startGeneration" :text="buttonText" class="btn btn-primary mt-2" style="width:100%" type="button" :disabled="isDisabled && !isLoading"/>
                        <p v-if="isError" class="mt- p-2 bg-red-100 text-red rounded-3">{{errorMessage}}</p>
                        <router-link :to="'/subscription'">
                            <p  v-if="isDisabled" class="mt-2 p-4 bg-red-100 text-black rounded-3 text-decoration-underline">You need a subscription to keep making materials. Click here to manage your subscription.</p>
                        </router-link>
                    </form>
                </div>
                  </div>
                  <div class="author-items-wrap mt-4">
                    <h3>Your Materials</h3>
                    <div class="mb-3"></div>
                    <div class="row g-gs" v-if="materials.length == 0">
                        <div class="col-md-10">
                            <div class="alert alert-secondary mt-3" :style="'text-align: left'" role="alert">
                                <h5>You don't have any materials yet!</h5>
                                <p class="pt-2">When you create materials they will appear here.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-gs" v-if="materials.length > 0">
                        <div class="col-md-12" v-for="material in materials" :key="material._id">
                            <div class="card card-full border border-primary">
                                <div class="card-body p-4">
                                    <div class="row">
                                        <div class="col-10">
                                            <h5 class="card-title text-truncate m-0">{{ material.name  }}</h5>
                                        </div>
                                        <div class="col-2">

                                            <button type="button" class="text-danger dropdown-item small" data-bs-toggle="modal" data-bs-target="#deleteMaterialModal" @click="materialToDelete=material">
                                                <em class="ni ni-trash"></em>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <textarea  v-if="!material.output" type="text" class="form-control form-control-s1 input-sm" :style="'height: 150px'" :value="'This material has not completed generating. If it has been more than a few minutes it has probably encountered an error.'" disabled></textarea>

                                            <textarea  v-if="material.output && material.output.choices[0].message" type="text" class="form-control form-control-s1 input-sm" :style="'height: 150px'" :value="material.output.choices[0].message.content" disabled></textarea>
                                        </div>
                                    </div>
                                    <div class="card-price-wrap d-flex align-items-center justify-content-between mb-3">
                                        <div class="me-2">
                                            <span class="card-price-title">Grade</span>
                                            <span class="card-price-number">{{ material.grade }}</span>
                                        </div>
                                        <div class="me-2">
                                            <span class="card-price-title">Subject</span>
                                            <span class="card-price-number">{{ material.subject }}</span>
                                        </div>
                                        <div class="text-sm-end">
                                            <span class="card-price-title">Type</span>
                                            <span class="card-price-number">{{ material.type }}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="btn-group dropdown w-100">
                                                <button type="" class="btn btn-sm btn-primary w-100 rounded" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Export <em class="ni ni-download"></em>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li>
                                                        <button class="dropdown-item small btn btn-sm" @click="downloadAsWordDoc(material.output.choices[0].message.content, material.name)">Export as Word Document</button>
                                                    </li>
                                                    <li>
                                                        <button class="dropdown-item small btn btn-sm" @click="downloadAsText(material.output.choices[0].message.content, material.name)">Export as Raw Text</button>
                                                    </li>
                                                 </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <div class="modal fade" id="deleteMaterialModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-body text-center">
                      <img :src="SectionData.deleteModal.img" alt="" class="mb-3">
                      <h4 class="modal-tilte mb-2">Are you sure?</h4>
                      <p class="modal-text">Are you sure you want to permanently delete this material?</p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm btn-default" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal" @click="deleteMaterial()">Delete</button>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal fade" id="deleteModal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                  <div class="modal-body text-center">
                      <img :src="SectionData.deleteModal.img" alt="" class="mb-3">
                      <h4 class="modal-tilte mb-2">Are you sure?</h4>
                      <p class="modal-text">Are you sure you want to permanently delete this folder?</p>
                  </div>
                  <div class="modal-footer">
                      <p class="small w-100">To confirm, type <span class="fst-italic">permanently delete</span> in the text input field.</p>
                      <input v-model="deleteConfirmation" class="form-control form-control-s1 input-sm" placeholder="permanently delete" type="text">
                      <button type="button" class="btn btn-sm btn-default" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal" @click="deleteModel()" v-if="deleteConfirmation=='permanently delete'">Delete</button>
                      <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal" v-if="deleteConfirmation!='permanently delete'" disabled>Delete</button>
                  </div>
              </div>
          </div>
      </div>
      <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
        <div id="notificationToast" class="toast " role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header d-flex bd-highlight">
            <img src="../../images/transparent-logo.png" class="rounded me-auto bd-highlight" width="100" height="100" alt="...">
            <strong class="bd-highlight p-0 fw-normal">{{ toastTitleText }}</strong>
            <button type="button" class="btn-close bd-highlight mt-0 pb-3" data-bs-dismiss="toast" aria-label="Close"><i class="ni ni-cross"></i></button>
        </div>
        <div class="toast-body bg-white">
            {{ toastBodyText }}
        </div>
        </div>
      </div>
  </section><!-- end author-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import { mapGetters } from "vuex";
import { Toast } from "bootstrap";

import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
const { createWorker } = require('tesseract.js');
const FilePond = vueFilePond(FilePondPluginImagePreview, FilePondPluginFileEncode);


export default {
    name: "MaterialGeneratorSection",
    data() {
        return {
            SectionData,
            materialSets: [],
            materials: [],
            textReferenceFiles: this.textReferenceFiles,
            materialCount: 0,
            materialSetsCount: 0,
            isLoading: false,
            buttonText: "Generate Material",
            userJoinDate: null,
            setToDelete: null,
            materialToDelete: null,
            deleteConfirmation: '',
            toastBodyText: "",
            toastTitleText: "",
            notificationToast: null,
            data: {},
            generationIsRunning: false,
            generationLog: "",
            isError: false,
            errorMessage: "",
            ocrLoading: false,
            filesToBeProcessed: [],
            filesThatHaveBeenOCRProcessed: [],
            ocrText: [],
            form: {
                name: "",
                subject: "",
                grade: "",
                type: "",
                referenceText: "",
                descriptionText: ""
            },
            options: [
                'Quiz',
                'Test',
                'Lesson',
                'Worksheet'
            ],
            grades: [
                'Kindergarten (Year 1)',
                '1st Grade (Year 2)',
                '2nd Grade (Year 3)',
                '3rd Grade (Year 4)',
                '4th Grade (Year 5)',
                '5th Grade (Year 6)',
                '6th Grade (Year 7)',
                '7th Grade (Year 8)',
                '8th Grade (Year 9)',
                '9th Grade (Year 10)',
                '10th Grade (Year 11)',
                '11th Grade (Year 12)',
                '12th Grade (Year 13)',
                'Collegiate'
            ],
            descriptionTextContent: "e.g. Do not include any questions about Rosaline.",
            referenceTextContent: `e.g. Sampson and Gregory, two servants of the house of Capulet, stroll through the streets of Verona. With bawdy banter, Sampson vents his hatred of the house of Montague. The two exchange punning remarks about physically conquering Montague men and sexually conquering Montague women. Gregory sees two Montague servants approaching, and discusses with Sampson the best way to provoke them into a fight without breaking the law.

Sampson bites his thumb at the Montagues—a highly insulting gesture. A verbal confrontation quickly escalates into a fight. Benvolio, a kinsman to Montague, enters and draws his sword in an attempt to stop the confrontation. Tybalt, a kinsman to Capulet, sees Benvolio’s drawn sword and draws his own. Benvolio explains that he is merely trying to keep the peace, but Tybalt professes a hatred for peace as strong as his hatred for Montagues, and attacks. The brawl spreads. A group of citizens bearing clubs attempts to restore the peace by beating down the combatants. Montague and Capulet enter, and only their wives prevent them from attacking one another. Prince Escalus arrives and commands that the fighting stop, on penalty of torture. The Capulets and Montagues throw down their weapons. The Prince declares the violence between the two families has gone on for too long and proclaims a death sentence upon anyone who disturbs the civil peace again. He says that he will speak to Capulet and Montague more directly on this matter; Capulet exits with him, the brawlers disperse, and Benvolio is left alone with his uncle and aunt, Montague and Lady Montague.

Benvolio describes to Montague how the brawl started. Lady Montague asks whether Benvolio has seen her son, Romeo. Benvolio replies that he earlier saw Romeo pacing through a grove of sycamores outside the city; since Romeo seemed troubled, Benvolio did not speak to him. Concerned about their son, the Montagues tell Benvolio that Romeo has often been seen melancholy, walking alone among the sycamores. They add that they have tried to discover what troubles him, but have had no success. Benvolio sees Romeo approaching, and promises to find out the reason for his melancholy. The Montagues quickly depart.

Benvolio approaches his cousin. With a touch of sadness, Romeo tells Benvolio that he is in love with Rosaline, but that she does not return his feelings and has in fact sworn to live a life of chastity. Benvolio counsels Romeo to forget her by gazing at other beauties, but Romeo contends that the woman he loves is the most beautiful of all. Romeo departs, assuring Benvolio that he cannot teach him to forget his love. Benvolio resolves to do just that.`
        }
    },
    components: { FilePond },
    computed: {
        isAuthResolved() {
        return this.$store.state.auth.isAuthResolved;
        },
        isLocationResolved() {
        return this.$store.state.meta.isLocationResolved;
        },
        ...mapGetters({
            user: "auth/authUser"
        }),
        isDisabled() {
            return !(this.user.generationCredits > 0 || this.user.isSubscribed)
        }
    },
    methods: {
        generate() {
            if (this.form.name && this.form.subject && this.form.type && this.form.grade) {
                this.isLoading = true;
                this.buttonText =  'Generating...';
                this.$store.dispatch("material/generateMaterial", this.form).then((res) => {
                    this.data = res.data;
                    this.isError = false;
                    this.$store
                    .dispatch("material/getUsersMaterials")
                    .catch((err) => {
                        console.log(err);
                        this.errorMessage = err.message;
                        this.isError = true;
                    })
                    .then((res) => {
                        this.materials = [];
                        for (let i = 0; i < res.data.generatedMaterials.data.length; i++) {
                            this.materials.push(res.data.generatedMaterials.data[i]);
                        }
                        this.materialsCount = res.data.generatedMaterials.count;

                        this.buttonText =  'Generate Material';
                        this.isLoading = false;
                        this.generationLog = "Finished.";
                    })
                }).catch((e) => {
                    this.buttonText =  'Generate Material';
                    // this.previewImage = this.sadPreviewImage;
                    this.isLoading = false;
                    this.isError = true;
                    this.errorMessage = e;
                });
            }
        },
        startGeneration() {
            if (this.form.name && this.form.subject && this.form.type && this.form.grade && !this.isLoading && !this.isDisabled) {
                this.isLoading = true;
                this.buttonText =  'Generating...';
                this.isProcessing = true;
                this.$store.dispatch("material/generateMaterial", this.form).then((res) => {
                    this.isError = false;
                    this.startCheckGenerationPolling(res.data.materialId)
                }).catch((e) => {
                    this.buttonText =  'Generate';
                    this.isLoading = false;
                    this.isError = true;
                    this.errorMessage = e;
                });
            }
        },
        startCheckGenerationPolling(id) {
                this.$store.dispatch("material/checkGeneration", { id: id }).then(async (res) => {
                    if (res.data.finished == 0) {
                        await this.wait(2000)
                        this.startCheckGenerationPolling(id)
                    } else {
                        this.$store.dispatch("material/getUsersMaterials")
                        .catch((err) => {
                            console.log(err);
                            this.errorMessage = err.message;
                            this.isError = true;
                        })
                        .then((res) => {
                            this.materials = [];
                            for (let i = 0; i < res.data.generatedMaterials.data.length; i++) {
                                this.materials.push(res.data.generatedMaterials.data[i]);
                            }
                            this.materialsCount = res.data.generatedMaterials.count;

                            this.buttonText =  'Generate Material';
                            this.isLoading = false;
                            this.generationLog = "Finished.";
                        })
                    }
                }).catch((e) => {
                    this.buttonText =  'Generate Material';
                    this.isLoading = false;
                    this.isError = true;
                    this.errorMessage = e;
                });
        },
        async handleFileAdded() {
            const files = this.$refs.pond.getFiles();
            await files.forEach(async (file) => {
                if (!this.filesThatHaveBeenOCRProcessed.includes(file.id)) {
                    this.filesThatHaveBeenOCRProcessed.push(file.id)
                    if (file.fileType == 'image/jpeg' || file.fileType == 'image/png' || file.fileType == 'image/jpg') {
                        this.ocrLoading = true;
                        const imageBase64Url = await file.getFileEncodeBase64String();
                        const imageText = await this.transcribeImage(imageBase64Url);
                        // this.form.referenceText += imageText;
                        this.ocrLoading = false;
                        this.filesToBeProcessed.filter((m) => m.name == file.filename)[0].text = imageText;
                    } else {
                        this.ocrLoading = true;
                        const base64String = await file.getFileEncodeBase64String();
                        const fileText = await this.parseOffice(base64String);
                        // this.form.referenceText += fileText;
                        this.ocrLoading = false;
                        this.filesToBeProcessed.filter((m) => m.name == file.filename)[0].text = fileText;
                    }
                    
                    if (this.filesThatHaveBeenOCRProcessed.length == this.filesToBeProcessed.length) {
                        for (let i = 0; i < this.filesToBeProcessed.length; i++) {
                            this.form.referenceText += this.filesToBeProcessed[i].text;
                            console.log(this.filesToBeProcessed[i])
                        }
                    }
                }
            });
        },
        async addFileStartHandler(e) {
            this.filesToBeProcessed.push({
                name: e.file.name,
                text: ""
            });
        },
        
        async getTextFromImage (url) {
            const worker = await createWorker();
            let imageText = "";
            await (async () => {
                await worker.loadLanguage('eng');
                await worker.initialize('eng');
                const { data: { text } } = await worker.recognize(url);
                imageText = text;
                await worker.terminate();
            })();

            return imageText;
        },
        async compressImage(base64Url, percent) {
            let dataUrl = "";
            await fetch(base64Url)
            .then(async (res) => {
                const blobImg = await res.blob()
                let bitmap = await createImageBitmap(blobImg);
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");
                canvas.width = bitmap.width;
                canvas.height = bitmap.height;
                ctx.drawImage(bitmap, 0, 0);
                dataUrl = await canvas.toDataURL("image/jpeg", percent/100);
            });
            console.log(dataUrl)
            return dataUrl;
        },

        formSubmit() {
            document.getElementById("vueLoadingButton").click();
        },
        wait (ms = 1000) {
            return new Promise(resolve => {
                setTimeout(resolve, ms);
            });
        },
        downloadAsWordDoc(text, filename) {
            var data = new Blob([text], {type: 'application/msword'});
            if (document.getElementById('download') !== null) {
                document.body.removeChild(document.getElementById('download'));
            }
            var a = document.createElement("a");
            a.setAttribute("id", "download");1
            a.setAttribute("download", filename);
            a.setAttribute("href", window.URL.createObjectURL(data));

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        downloadAsText (text, filename) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        async deleteMaterial() {
            await this.$store.dispatch("material/deleteGeneratedMaterial", this.materialToDelete)
            .then((res) => {
                this.$store
                .dispatch("material/getUsersMaterials")
                .catch((err) => {
                    console.log(err);
                    this.errorMessage = err.message;
                    this.isError = true;
                })
                .then((res) => {
                    this.materials = [];
                    for (let i = 0; i < res.data.generatedMaterials.data.length; i++) {
                        this.materials.push(res.data.generatedMaterials.data[i]);
                    }
                    this.materialsCount = res.data.generatedMaterials.count;
                    this.toastTitleText = "Material Deleted"
                    this.toastBodyText = "Material deleted successfully!"
                    this.notificationToast.show();
                })
            });
        },
        async deleteMaterialSet() {
            await this.$store.dispatch("material/deleteMaterialSet", this.materialSetToDelete)
            .then((res) => {
                this.$store
                .dispatch("material/getUsersMaterials")
                .catch((err) => {
                    console.log(err);
                    this.errorMessage = err.message;
                    this.isError = true;
                })
                .then((res) => {
                    this.materials = [];
                    for (let i = 0; i < res.data.generatedMaterials.data.length; i++) {
                        this.materials.push(res.data.generatedMaterials.data[i]);
                    }
                    this.materialsCount = res.data.generatedMaterials.count;
                    this.toastTitleText = "Material Deleted"
                    this.toastBodyText = "Material deleted successfully!"
                    this.notificationToast.show();
                })
            });
        },
        async transcribeImage(base64String) {
            const data = {
                image: base64String
            }
            let text = '';
            await this.$store.dispatch("material/transcribeImage", data)
            .then((res) => {
                if (res.data.wasSuccessful) {
                    text = res.data.text;
                }
            });
            
            return text;
        },
        async parseOffice(base64) {
            const data = {
                file: base64
            }
            let text = '';
            await this.$store.dispatch("material/parseOffice", data)
            .then((res) => {
                if (res.data.wasSuccessful) {
                    text = res.data.text;
                }
            });
            
            return text;
        }
    },
    async mounted () {
        this.userJoinDate = new Date(this.user.createdAt).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
        this.$store.dispatch('auth/getAuthUser', true).catch((err) => {
            console.log(err);
        })

        // Chat Auth
        // const iframe = document.getElementById("authIFrame");
        // const data = localStorage.getItem("auth-jwt"); // Get data from local storage
        // iframe.contentWindow.postMessage(data, "*");
        // window.onload = function() {
        //     var win = document.getElementsByTagName('iframe')[0].contentWindow;
        //     win.postMessage(data, "*");
        // };

        this.$store
        .dispatch("material/getUsersMaterials")
        .catch((err) => {
            console.log(err);
            this.errorMessage = err.message;
            this.isError = true;
        })
        .then((res) => {
            this.materials = [];
            for (let i = 0; i < res.data.generatedMaterials.data.length; i++) {
                this.materials.push(res.data.generatedMaterials.data[i]);
            }
            this.materialsCount = res.data.generatedMaterials.count;
        })

        this.$store
        .dispatch("material/getAvailableMaterialTypes")
        .catch((err) => {
            console.log(err);
            this.errorMessage = err.message;
            this.isError = true;
        })
        .then((res) => {
            this.options = [];
            for (let i = 0; i < res.data.length; i++) {
                this.options.push(res.data[i]);
            }
        })

        // this.$store
        // .dispatch("model/getBaseDreamBoothModels")
        // .catch((err) => {
        //     console.log(err);
        //     this.errorMessage = err.message;
        //     this.isError = true;
        // })
        // .then((res) => {

        //     for (let i = 0; i < res.data.dreamBoothModels.data.length; i++) {
        //         this.baseDreamBoothModels.push(res.data.dreamBoothModels.data[i]);
        //     }
        //     this.baseDreamBoothModelCount = res.data.dreamBoothModels.count;
        // })
        var toastElList = [].slice.call(document.querySelectorAll('.toast'))
        var toastList = toastElList.map(function (toastEl) {
        return new Toast(toastEl, {animation: true, autohide: true, delay: 4000});
        })
        var myToastEl = document.getElementById('notificationToast');
        var myToast = Toast.getInstance(myToastEl);
        this.notificationToast = myToast;

        const files = this.$refs.pond.getFiles();
    }
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .btn,
 .author-link,
 .card-price-wrap {
   z-index: 2;
   position: relative;
 }

 .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #7952b3;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
 
</style>