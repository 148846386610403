<template>
    <section class="register-section section-space-b pt-4 pt-md-5 mt-md-3">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 mb-lg-0 d-none d-lg-block">
                        <img :src="SectionData.loginData.img" alt="" class="img-fluid">
                    </div><!-- end col-lg-6 -->
                    <div class="col-lg-6 col-md-9">
                        <div class="section-head-sm">
                            <h2 class="mb-2" v-html=" SectionData.registerData.title"></h2>
                            <p>{{ SectionData.registerData.subTitle }}</p>
                        </div>
                        <form action="#">
                            <div class="form-floating mb-4">
                                <input type="text" class="form-control" id="firstName" placeholder="Name" v-model="form.firstName">
                                <label for="firstName">First name</label>
                            </div><!-- end form-floating -->
                            <div class="form-floating mb-4">
                                <input type="text" class="form-control" id="lastName" placeholder="Name" v-model="form.lastName">
                                <label for="lastName">Last name</label>
                            </div><!-- end form-floating -->
                            <div class="form-floating mb-4">
                                <input  type="text" class="form-control" id="userName" placeholder="Username" v-model="form.username">
                                <label for="userName">Username</label>
                            </div><!-- end form-floating -->
                            <div class="form-floating mb-4">
                                <input type="email" class="form-control" id="emailAddress" placeholder="name@example.com" v-model="form.email">
                                <label for="emailAddress">Email address</label>
                            </div><!-- end form-floating -->
                            <div class="form-floating mb-4">
                                <input type="password" class="form-control password" id="password" placeholder="Password" v-model="form.password">
                                <label for="password">Password</label>
                                <a href="password" class="password-toggle" title="Toggle show/hide pasword">
                                    <em class="password-shown ni ni-eye-off"></em>
                                    <em class="password-hidden ni ni-eye"></em>
                                </a>
                            </div><!-- end form-floating -->
                            <div class="form-floating mb-4">
                                <input type="password" class="form-control password" id="confirmPassword" placeholder="Confirm Password" v-model="form.passwordConfirmation">
                                <label for="password">Confirm Password</label>
                                <!-- <a href="password" class="password-toggle confirmPassword-toggle" title="Toggle show/hide pasword">
                                    <em class="password-shown ni ni-eye-off"></em>
                                    <em class="password-hidden ni ni-eye"></em>
                                </a> -->
                            </div>
                            <p class="mb-4 form-text" >By signing up you agree to our <a href="https://app.getterms.io/view/A0hYC/tos/en-us">Terms of Service</a> and  <a href="https://app.getterms.io/view/A0hYC/privacy/en-us">Privacy Policy</a>.</p>
                            <button @click.prevent="register" class="btn btn-primary w-100" type="submit">{{ SectionData.registerData.btnText }}</button>
                            <!-- <span class="d-block my-4">— or sign up with —</span>
                            <ul class="btns-group d-flex">
                                <li class="flex-grow-1" v-for="(list, i) in SectionData.registerData.btns" :key="i"><router-link :to="list.path" class="btn d-block" :class="list.btnClass"><em class="ni" :class="list.icon"></em> {{ list.title }} </router-link></li>
                            </ul> -->
                            <p class="mt-3 form-text">{{ SectionData.registerData.haveAccountText }} <router-link :to="SectionData.registerData.btnTextLink" class="btn-link text-primary">{{ SectionData.registerData.btnTextTwo }}</router-link></p>
                        </form>
                    </div><!-- end col-lg-6 -->
                </div><!-- end row -->
            </div><!-- end container -->
        </section><!-- end register-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
export default {
  name: 'RegisterSection',
  data () {
    return {
      SectionData,
      form: {
        username: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: "",
        postal: null,
        country: "US",
        email: null,
        avatar: null,
        password: null,
        passwordConfirmation: null,
      },
    }
  },
  methods: {
    register() {
      const ref = this;
    //   this.$v.form.$touch();
      this.$store
        .dispatch("auth/registerUser", this.form)
        .then(function () {
        //   ref.$gtag.query('event', 'conversion', {'send_to': 'AW-845401249/qSkdCJDkhPkCEKGZj5MD'})
        //   ref.$gtag.event(['Register'], {
        //     'event_category': 'engagement',
        //   })
          ref.$router.push("/login")
        })
        .catch((errMessage) => {
          this.$toasted.error(errMessage, { duration: 5000 });
        });
      // window.fbq('track', 'CompleteRegistration');
    },
    rndStr(len) {
      let text = "";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    randAvatar() {
      this.avatarReady = false;
      let random = this.rndStr(8);
      this.avatarSeed = random;
      this.form.avatar =
        "https://avatars.dicebear.com/v2/avataaars/" +
        this.avatarSeed +
        ".svg?options[mouth][]=smile";
      this.avatarReady = true;
    },
  },
  mounted () {
    /*  ======== Show/Hide passoword ======== */
    function showHidePassword(selector) {
        let elem = document.querySelectorAll(selector);
        if(elem.length > 0){
            elem.forEach(item => {
            item.addEventListener("click", function(e){
                e.preventDefault();
                let target = document.getElementById(item.getAttribute("href"));
                if(target.type == "password") {
                target.type = "text";
                item.classList.add("is-shown");
                }else{
                target.type = "password";
                item.classList.remove("is-shown");
                }
            });

            });
        }
    }

    // function showHideConfirmPassword(selector){
    //     let elem = document.querySelectorAll(selector);
    //     if(elem.length > 0){
    //         elem.forEach(item => {
    //         item.addEventListener("click", function(e){
    //             e.preventDefault();
    //             let target = document.getElementById(item.getAttribute("href"));
    //             if(target.type == "password") {
    //             target.type = "text";
    //             item.classList.add("is-shown");
    //             }else{
    //             target.type = "password";
    //             item.classList.remove("is-shown");
    //             }
    //         });

    //         });
    //     }
    // }
    

    showHidePassword(".password-toggle");
    // showHideConfirmPassword(".password-toggle");
  }
}
</script>