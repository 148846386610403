// import * as Vue from 'vue'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

function checkTokenValidity (token) {
  if (token) {
    const decodedToken = jwt.decode(token)

    return decodedToken && (decodedToken.exp * 1000) > new Date().getTime()
  }

  return false
}

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {
    authUser (state) {
      return state.user || null
    },
    isAuthenticated (state) {
      return !!state.user
    },
    isMeetupOwner: (state) => (meetupCreatorId) => {
      if (!state.user) return false
      return state.user._id === meetupCreatorId
    },
    isMember: (state) => (meetupId) => {
      return state.user &&
             state.user['joinedMeetups'] &&
             state.user['joinedMeetups'].includes(meetupId)
    }
  },
  actions: {
    loginWithEmailAndPassword ({commit}, userData) {
      return axios.post('/api/v1/users/login', userData)
        .then(res => {
          const user = res.data
          localStorage.setItem('auth-jwt', user.token)
          commit('setAuthUser', user)
        })
        .catch(err => rejectError(err))
    },
    registerUser (context, userData) {
      return axios.post('/api/v1/users/register', userData)
        .catch(err => rejectError(err))
    },
    logout ({commit}) {
      // For Session Authnetication !
      // return axios.post('/api/v1/users/logout')
      //   .then(() => {
      //     commit('setAuthUser', null)
      //     return true
      //   })
      //   .catch(err => {
      //     return err
      //   })

      return new Promise((resolve) => {
        localStorage.removeItem('auth-jwt')
        commit('setAuthUser', null)
        resolve(true)
      })
    },
    getAuthUser ({commit, getters}, forceReload = false) {
      const authUser = getters['authUser']
      const token = localStorage.getItem('auth-jwt')
      const isTokenValid = checkTokenValidity(token)

      if (!forceReload) {
        if (authUser && isTokenValid) { return Promise.resolve(authUser) }
      }

      const config = {
        headers: {
          'Cache-Control': 'no-cache'
        }
      }

      return axiosInstance.get('/api/v1/users/me', config)
        .then((res) => {
          const user = res.data
          localStorage.setItem('auth-jwt', user.token)
          commit('setAuthUser', user)
          commit('setAuthState', true)
          return user
        })
        .catch(err => {
          commit('setAuthUser', null)
          commit('setAuthState', true)
          const error = err;
          return "Error: " + error;
        })
    },
    addMeetupToAuthUser ({commit, state}, meetupId) {
      const userMeetups = [...state.user['joinedMeetups'], meetupId]
      commit('setMeetupsToAuthUser', userMeetups)
    },
    removeMeetupFromAuthUser ({commit, state}, meetupId) {
      const userMeetupsIds = [...state.user['joinedMeetups']]
      const index = userMeetupsIds.findIndex(userMeetupId => userMeetupId === meetupId)

      userMeetupsIds.splice(index, 1)
      commit('setMeetupsToAuthUser', userMeetupsIds)
    },
    updateUser ({commit}, user) {
      return axiosInstance.patch(`/api/v1/users/${user._id}`, user)
        .then(res => {
          const updatedUser = res.data
          commit('setAuthUser', updatedUser)
          return updatedUser
        })
    },
    changePassword ({commit}, user) {
      return axiosInstance.post(`/api/v1/users/changepassword`, user)
        .then(res => {
          return res
        })
    },
    addQuestion ({rootState}, faq) {
      faq.user = rootState.auth.user

      return axiosInstance.post(`/api/v1/users/faqs/${faq.user._id}`, faq)
        .then(res => res.data)
    },
    addPlan ({rootState}, plan) {
      plan.user = rootState.auth.user

      return axiosInstance.post(`/api/v1/users/plans/${plan.user._id}`, plan)
        .then(res => res.data)
    },
    addListingFree ({rootState}, listing) {
      listing.user = rootState.auth.user

      return axiosInstance.post(`/api/v1/users/listings/${listing.user._id}`, listing)
        .then(res => res.data)
    },
    deletePlan ({rootState}, plan) {
      plan.user = rootState.auth.user

      return axiosInstance.post(`/api/v1/users/deleteplan/${plan.user._id}`, plan)
        .then(res => res.data)
    },
    // markAsSold ({rootState}, markAsSold) {
    //   markAsSold.user = rootState.auth.user

    //   return axiosInstance.post(`/api/v1/users/markassold/${markAsSold.user._id}`, markAsSold)
    //     .then(res => res.data)
    // },
    updatePlan ({rootState}, plan) {
      plan.user = rootState.auth.user

      return axiosInstance.post(`/api/v1/users/updateplan/${plan.user._id}`, plan)
        .then(res => res.data)
    },

    sendMessage ({rootState}, params)  {
      params.user = rootState.auth.user
      return axiosInstance.post(`/api/v1/users/sendmessage/`, params)
        .then(res => res.data)
    },

    changePrimaryColor ({rootState}, params)  {
      params.user = rootState.auth.user
      return axiosInstance.post(`/api/v1/users/changeprimarycolor/`, params)
        .then(res => res.data)
    },

    contactSendMessage ({rootState}, params)  {
      params.user = rootState.auth.user
      return axiosInstance.post(`/api/v1/users/contactsendmessage/`, params)
        .then(res => res.data)
    },

    sendResetPasswordEmail ({rootState}, params)  {
      params.user = rootState.auth.user
      return axiosInstance.post(`/api/v1/users/sendpasswordresetemail/`, params)
        .then(res => res.data)
    },

    resetPassword ({rootState}, params)  {
      params.user = rootState.auth.user
      return axiosInstance.post(`/api/v1/users/resetpassword/`, params)
        .then(res => res.data)
    },

    subscribe ({rootState}, params)  {
      params.user = rootState.auth.user
      return axiosInstance.post(`/api/v1/users/subscribe/`, params)
        .then(res => res.data)
    },

    createCheckoutSession ({rootState}, params)  {
        params.user = rootState.auth.user
        return axiosInstance.post(`/api/v1/payment/create-checkout-session`, params)
        .then(res => res.data)
    },

    createDreamBoothProductCheckoutSession ({rootState}, params)  {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
        return axiosInstance.post(`/api/v1/payment/create-dream-booth-product-checkout-session`, params)
        .then(res => res.data)
    },

    createCreditCheckoutSession ({rootState}, params)  {
      if (rootState.auth && rootState.auth.user) {
        params.user = rootState.auth.user
      }
        return axiosInstance.post(`/api/v1/payment/create-credit-checkout-session`, params)
        .then(res => res.data)
    },

    createCustomerPortalSession ({rootState})  {
      const user = rootState;
      return axiosInstance.post(`/api/v1/payment/customer-portal`, user)
      .then(res => res.data)
  },

    onboardUser ({rootState})  {
      const user = rootState;
      return axiosInstance.post(`/api/v1/payment/onboard-user`, user)
      .then(res => res.data)
  },

    onboardUserReturn ({rootState})  {
      const user = rootState;
      return axiosInstance.get(`/api/v1/payment/onboard-user/return`, user)
      .then(res => res.data)
  },

    onboardUserRefresh ({rootState})  {
    const user = rootState;
    return axiosInstance.get(`/api/v1/payment/onboard-user/refresh`, user)
    .then(res => res.data)
    },
  },
  mutations: {
    setAuthUser (state, user) {
      return state.user = user
    },
    setAuthState (state, authState) {
      return state.isAuthResolved = authState
    },
    // setMeetupsToAuthUser (state, meetups) {
    //   return Vue.set(state.user, 'joinedMeetups', meetups)
    // },
  }
}