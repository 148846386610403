<template>
    <div class="card card-full">
        <div class="card-image">
            <!-- <img :src="item.image" class="card-img-top" alt="art image"> -->
            <img v-lazy="item.images[0].src" class="card-img-top" :alt="item.title">
        </div>
        <div class="card-body">
            <h5 class="card-title mb-0">{{ item.title }}</h5>
            <div class="card-author mb-1 d-flex align-items-center">
                <!-- <span class="me-1 card-author-by">By @anonymous</span> -->
                <!-- <div class="custom-tooltip-wrap">
                    <router-link :to="item.views" class="custom-tooltip author-link">{{ product.author }}</router-link>
                    <div class="card-generic custom-tooltip-dropdown">
                        <div class="author-action d-flex flex-wrap align-items-center mb-3">
                            <div class="flex-shrink-0 avatar">
                                <img :src="product.avatar" alt="avatar">
                            </div>
                            <div class="ms-2">
                                <span class="author-username">Anonymous</span>
                                <span class="author-follow-text">Hello</span>
                            </div>
                        </div>
                        <h6 class="author-name mb-1">{{ item.views }}</h6>
                        <p class="author-desc smaller mb-3">{{ item.views }}</p>
                        <div class="follow-wrap mb-3">
                            <h6 class="mb-1 smaller text-uppercase">Followed by</h6>
                            <div class="avatar-group">
                                <router-link :to="avatar.path" v-for="avatar in item.avatars" :key="avatar.id">
                                    <img :src="avatar.img" alt="avatar">
                                </router-link>
                            </div>
                        </div>
                        <router-link :to="item.views" class="btn btn-sm bg-dark-dim">Follow</router-link>
                    </div>
                </div> -->
            </div>
            <div class="card-price-wrap d-flex align-items-center justify-content-sm-between">
                <div class="me-5 me-sm-2">
                    <span class="card-price-number mt-2">${{ item.price / 100}}</span>
                    <span class="card-price-title">Free Shipping</span>
                </div>
                <div class="text-sm-end">
                    <router-link :to="{
                        name: 'GenerationProductDetail',
                        params: {
                        generationurl: item.generationUrl,
                        title: item.url
                        }
                    }">
                    <span class="btn btn-sm btn-primary">Buy now</span>
                    </router-link>
                </div>
            </div><!-- end card-price-wrap -->
            <!-- <span class="btn btn-sm btn-dark">Place Bid</span> -->
        </div><!-- end card-body -->
        <router-link
            class="details"
            :to="'/book/' + item.url"
        >
        </router-link>
    </div><!-- end card -->
</template>
<script>
import { createPopper } from '@popperjs/core';
export default {
  name: 'ExploreItems',
  props: ['item'],
  mounted () {
    /*============= Custom Tooltips =============== */
    function customTooltip(selector, active) {
    let elem = document.querySelectorAll(selector);
        if(elem.length > 0){
            elem.forEach(item => {
                const parent = item.parentElement;
                const next = item.nextElementSibling;
                createPopper(item, next);
                parent.addEventListener("mouseenter", function() {
                    parent.classList.add(active)
                });
                parent.addEventListener("mouseleave", function() {
                    parent.classList.remove(active)
                });
            });
        }
    }

    customTooltip('.custom-tooltip','active');

  }
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .author-link,
 .card-price-wrap {
   z-index: 2;
   position: relative;
 }
</style>