<template>
<section class="relatedBooks">
    <div class="container">
        <swiper v-if="books"
            :slides-per-view="4"
            
            :pagination="{
                dynamicBullets: false,
                clickable: true
            }"
            :breakpoints="{
              0: {
                  slidesPerView: 1
              },
              767: {
                  slidesPerView: 1
              },
              992: {
                  slidesPerView: 3
              },
              1200: {
                  slidesPerView: 4
              }
            }">
            <swiper-slide v-for="book in books" :key="book._id">
                <div class="card card-full border bg-light mb-4 bookCard">
                    <div class="card-image h-100">
                        <router-link :to="{
                                name: 'GutenbergBookDetail',
                                params: {
                                    url: book.url
                                }
                            }">
                                <img v-lazy="book.formats['image/jpeg']" class="card-img-top" style="height: 366.75200px;" :alt="book.title + ' cover'">
                          </router-link>
                    </div>
                    <div class="card-body p-2 bg-light rounded-bottom">
                        <router-link :to="{
                                name: 'GutenbergBookDetail',
                                params: {
                                    url: book.url
                                }
                            }">
                            <span class="fw-bold d-inline-block text-truncate link mb-0">{{ book.title  }}</span>
                        </router-link>
                        <div class="card-author mb-1 d-flex align-items-center" v-if="book.authors && book.authors[0]">
                            <span class="me-1 card-author-by">By</span>
                            <div class="custom-tooltip-wrap">
                                <router-link :to="'/books/' + book.authors[0].name" class="author-link">{{ book.authors[0].name }}</router-link>
                            </div>
                        </div>
                        <div class="mt-3">
                          <router-link :to="{
                                name: 'GutenbergBookDetail',
                                params: {
                                    url: book.url
                                }
                            }">
                            <span class="btn btn-sm btn-primary w-100">View Materials</span>
                          </router-link>

                        </div>
                        <!-- <span class="card-price-title text-white mt-2" :style="'font-size: 12px; float: right'">Free Shipping</span> -->
                    </div><!-- end card-body -->
                </div>
            </swiper-slide>
        </swiper>
    </div>
</section>
</template>

<script>
import SectionData from '@/store/store.js'
import SwiperCore, { Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";

SwiperCore.use([Pagination]);

import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'RelatedBooks',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
        books: [Object]
    },
  data() {
    return {
      SectionData
    }
  },
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .card-price-wrap {
   z-index: 2;
   position: relative;
 }
 .bookCard:hover {
    margin: -4px;
 }

 .text-truncate {
    max-width: 200px;
 }
</style>
