<template>
    <router-link to="/" class="logo-link" :class="classname">
        <img class="logo-dark logo-img-lg" :src="require('@/images/transparent-logo-trimmed.png')" alt="logo">
        <!-- <span class="border border-2 border-danger text-danger rounded p-2 py-1 fw-bold logo-dark">Beta</span> -->
        <!-- <img class="logo-light logo-img-lg rounded" :src="require('@/images/transparent-logo-trimmed-whitebg.png')" alt="logo"> -->
        <!-- <h1 class="text-primary">TeachNice</h1> -->
    </router-link>
</template>
<script>

export default {
  name: 'LogoLink',
  props: ['classname']
}
</script>
