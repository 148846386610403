<template>
  <section>
      <form class="">
        <p class="mb-2">Sign up for our newsletter to get updated when new features drop!</p>
        <div class="">
          <input  v-model="subscribeForm.email" type="email" class="form-control input-sm" placeholder="you@example.com" aria-label="Your email…" />
          <a class="btn btn-sm bg-light hover:bg-primary hover:text-primary shadow w-100 mt-2" @click="subscribe()">Subscribe</a>
        </div>
        <!-- Success message -->
        <p class="text-sm text-gray-400 mt-3" v-if="isThanks">Thanks for subscribing!</p>
        <!-- <p class="mt-2">No spam. You can unsubscribe at any time.</p> -->
      </form>
  </section>
</template>Free Generator

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: 'Newsletter',
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    isFormInvalid() {
      return this.$v.subscribeForm.$invalid;
    },
  },
  data() {
    return {
      subscribeForm: {
        email: null,
      },
      isThanks: false
    };
  },
  validations: {
    subscribeForm: {
      email: {
        required,
      },
    },
  },
  methods: {
    subscribe: function () {
      //window.fbq('track', 'ContactFormSubmitted');
      const params = [this.subscribeForm];
      this.$store
        .dispatch("auth/subscribe", params)
        .then(() => {
          this.subscribeForm.email = "";
          this.isThanks = true;
          return new Promise(function (resolve) {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        })
        .catch((err) => {
          console.log(err);
          this.subscribeForm.email = "";
          this.$toasted.error("Oops, something bad happened...", {
            duration: 1000,
          });
          return new Promise(function (resolve) {
            setTimeout(() => {
              resolve();
            }, 2000);
          });
        });
    },
  },
};
</script>