<template>
    <div class="header-main is-sticky" :class="isTransparent">
        <div class="container">
            <div class="header-wrap">
                <!-- logo -->
                <Logo></Logo>
                <!-- mobile action -->
                <MobileAction v-if="!user"></MobileAction>
                <MobileActionTwo v-if="user"></MobileActionTwo>

                <!-- header search -->
                <!-- <HeaderSearch class="header-search-form-s2"></HeaderSearch> -->

                <!-- Menu -->
                <Menu v-if="!user" classname="btn-primary"></Menu>
                <MenuTwo v-if="user" classname="btn-primary"></MenuTwo>
                <div class="header-overlay"></div>
            </div><!-- .header-warp-->
        </div><!-- .container-->
    </div><!-- .header-main-->
</template>
<script>
// @ is an alias to /src
import Logo from '@/components/common/Logo.vue'
import MobileAction from '@/components/common/MobileAction.vue'
import MobileActionTwo from '@/components/common/MobileActionTwo.vue'
// import HeaderSearch from '@/components/common/HeaderSearch.vue'
import Menu from '@/components/common/Menu.vue'
import MenuTwo from '@/components/common/MenuTwo.vue'

import { mapGetters } from "vuex";

export default {
  name: 'HeaderMain',
  props: ['isTransparent'],
  components: {
    Logo,
    MobileAction,
    MobileActionTwo,
    // HeaderSearch,
    Menu,
    MenuTwo
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    isLocationResolved() {
      return this.$store.state.meta.isLocationResolved;
    },
    ...mapGetters({
      user: "auth/authUser",
    })
  }
}
</script>
