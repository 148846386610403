// import * as Vue from 'vue'
import axios from 'axios'
import { rejectError } from '@/helpers'
import axiosInstance from '@/services/axios'


export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {

  },
  actions: {
    getBlogPost ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axios.get('/api/v1/blog/getblogpost?url=' + params.url)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getBlogPosts ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      let url = '/api/v1/blog/getblogposts?sort=' + params.sort;
      if (params.searchTerm) {
        url += '&searchTerm=' + params.searchTerm
      }
      if (params.limit) {
        url += '&limit=' + params.limit
      }
      if (params.category) {
        url += '&category=' + params.category
      }
      if (params.tag) {
        url += '&tag=' + params.tag
      }
      if (params.page) {
        url += '&page=' + params.page
      }
      return axios.get(url)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
  },
  mutations: {
  }
}