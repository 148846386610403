// import * as Vue from 'vue'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {
  },
  actions: {
    createSet ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/materials/createset', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    generateMaterial ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/materials/generatematerial', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    getUsersMaterialSets ({rootState}) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/materials/getusersmaterialsets')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    getUsersMaterials ({rootState}) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/materials/getusersmaterials')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getAvailableMaterialTypes ({rootState}) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/materials/getavailablematerialtypes')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getAvailableChapterMaterials ({rootState}) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/materials/getavailablechaptermaterials')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    checkGeneration ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/materials/checkgeneration', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    deleteGeneratedMaterial ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/materials/deletegeneratedmaterial', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    deleteMaterialSet ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/materials/deletematerialset', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    createGutenbergBookChapterMaterialCheckoutSession ({rootState}, params)  {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
        return axiosInstance.post(`/api/v1/payment/creategutenbergbookchaptermaterialcheckoutsession`, params)
        .then(res => res.data)
    },

    
    transcribeImage ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/materials/transcribeimage', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    
    parseOffice ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/materials/parseoffice', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    // getProductByPredictionUrlAndTitle ({rootState}, params) {
    //   if (rootState.auth && rootState.auth.user) {
    //     // params.user = rootState.auth.user
    //   }
    //   return axiosInstance.get('/api/v1/models/getproductbypredictionurlandtitle?url=' + params.url + "&title=" + params.title + "&username=" + params.username)
    //     .then((data) => {
    //       return data
    //     })
    //     .catch(err => rejectError(err))
    // }
  }
}