<!-- <template>
  <router-view :key="$route.path"/>
</template>

<script>
export default {
  name: 'App',
}
</script> -->
<template>
  <router-view :key="$route.path" />
</template>

<script>
const jqueryScript = document.createElement("script");
jqueryScript.setAttribute(
  "src",
  "//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"
);
document.head.appendChild(jqueryScript);
// import Footer from "@/components/cruip/partials/Footer.vue";
//import TheFooter from "@/components/shared/TheFooter";
import { mapGetters } from "vuex";
//import Header from '@/components/cruip/partials/Header.vue'
//import BurgerNavbar from "@/components/shared/BurgerNavbar";
// import AOS from "aos";
// import Sticky from 'sticky-js'
// import { focusHandling } from 'cruip-js-toolkit'

//import Lingallery from 'lingallery';
//Vue.component('lingallery', Lingallery);
//import Slider from '@/components/shared/Slider'

export default {
  name: "app",
  components: {
    // Footer
    //Header
    //BurgerNavbar,
    //'slider': Slider
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    isLocationResolved() {
      return this.$store.state.meta.isLocationResolved;
    },
    ...mapGetters({
      user: "auth/authUser",
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
  },
  // mounted() {
  //   this.$Progress.finish();
  //   AOS.init({
  //     once: true,
  //     disable: "phone",
  //     duration: 700,
  //     easing: "ease-out-cubic",
  //   });
  //   // eslint-disable-next-line no-unused-vars
  //   const sticky = new Sticky("[data-sticky]");
  //   focusHandling();
  //   // Route change
  //   if (this.$router) {
  //     this.$watch("$route", () => {
  //       // eslint-disable-next-line no-unused-vars
  //       const sticky = new Sticky("[data-sticky]");
  //       focusHandling("outline");
  //     });
  //   }
  // },
  // created() {
  //   // this.$store.dispatch("meta/fetchMetaData");

  //   // PROGRESS BAR
  //   //  [App.vue specific] When App.vue is first loaded start the progress bar
  //   this.$Progress.start();
  //   //  hook the progress bar to start before we move router-view
  //   this.$router.beforeEach((to, from, next) => {
  //     //  does the page we want to go to have a meta.progress object
  //     if (to.meta.progress !== undefined) {
  //       let meta = to.meta.progress;
  //       // parse meta tags
  //       this.$Progress.parseMeta(meta);
  //     }
  //     //  start the progress bar
  //     this.$Progress.start();
  //     //  continue to next page
  //     next();
  //   });
  //   //  hook the progress bar to finish after we've finished moving router-view
  //   this.$router.afterEach(() => {
  //     //  finish the progress bar
  //     this.$Progress.finish();
  //   });
  //   // PROGRESS BAR END
  // },
};
</script>
<style lang="scss">
</style>
