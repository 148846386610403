<template>
  <div class="col-lg-9 ps-xl-5">
        <div class="user-panel-title-box">
            <h3>Account Settings</h3>
        </div><!-- end user-panel-title-box -->
        <div class="profile-setting-panel-wrap">
            <ul class="nav nav-tabs nav-tabs-s1 nav-tabs-mobile-size" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="list in SectionData.editProfileData.editProfileTabNav" :key="list.id">
                    <button class="nav-link" :class="list.isActive" :id="list.slug" data-bs-toggle="tab" :data-bs-target="list.bsTarget" type="button">{{ list.title }}</button>
                </li>
            </ul>
            <div class="tab-content mt-4" id="myTabContent">
                <div class="tab-pane fade show active" id="account-information" role="tabpanel" aria-labelledby="account-information-tab">
                    <div class="profile-setting-panel" v-if="user">
                        <h5 class="mb-4">Edit Profile</h5>
                        <!-- <div class="d-flex align-items-center">
                            <div class="image-result-area avatar avatar-3">
                                <img id="image-result" :src="user.avatar" alt="">
                            </div>
                            <div class="p-2 mt-4 w-50">
                                <file-pond id="filepond" name="files" ref="pond" label-idle="<u>Click<u/> to update profile picture..." v-bind:allow-multiple="false" accepted-file-types="image/jpeg, image/png, image/jpg" v-bind:credits="false" :server="'/api/uploadprofilepicture/'+this.user._id" v-bind:files="updateUserInfo.profilePicture" v-on:init="handleFilePondInit" />
                            </div>
                        </div> -->
                        <div class="row mt-4">
                            <div class="col-lg-4 mb-3">
                                <label for="displayName" class="form-label">First Name</label>
                                <input type="text" id="displayName" class="form-control form-control-s1" v-model="user.firstName">
                            </div>
                            <div class="col-lg-4 mb-3">
                                <label for="displayName" class="form-label">Last Name</label>
                                <input type="text" id="displayName" class="form-control form-control-s1" v-model="user.lastName">
                            </div>
                            <div class="col-lg-4 mb-3">
                                <label for="displayUserName" class="form-label">Username</label>
                                <input type="text" id="displayUserName" class="form-control form-control-s1" v-model="user.username" disabled>
                            </div><!-- end col -->
                        </div><!-- end row -->
                        <div class="mb-3">
                            <label for="bio" class="form-label">Bio</label>
                            <textarea class="form-control form-control-s1" placeholder="Leave a comment here" id="bio" v-model="user.bio"></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="emailAddress" class="form-label">Email</label>
                            <input type="email" id="emailAddress" class="form-control form-control-s1" v-model="user.email" disabled>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 mb-3">
                                <label for="facebookLink" class="form-label">Facebook</label>
                                <input type="text" id="facebookLink" class="form-control form-control-s1" v-model="user.facebookUrl">
                            </div><!-- end col -->
                            <div class="col-lg-6 mb-3">
                                <label for="twitterLink" class="form-label">Twiiter</label>
                                <input type="text" id="twitterLink" class="form-control form-control-s1" v-model="user.twitterUrl">
                            </div><!-- end col -->
                            <div class="col-lg-6 mb-3">
                                <label for="instagramLink" class="form-label">Instagram</label>
                                <input type="text" id="instagramLink" class="form-control form-control-s1" v-model="user.instagramUrl">
                            </div><!-- end col -->
                            <div class="col-lg-6 mb-3">
                                <label for="webLink" class="form-label">Website</label>
                                <input type="text" id="webLink" class="form-control form-control-s1" v-model="user.websiteUrl">
                            </div><!-- end col -->
                        </div><!-- end row -->
                        <button class="btn btn-primary mt-3" type="button" @click="updateUser()">Update Profile</button>
                        <p class="text-success mt-2" v-if="updateUserInfo.wasSuccessful == true">{{ updateUserInfo.message }}</p>
                        <p class="text-danger mt-2" v-if="updateUserInfo.wasSuccessful != null && updateUserInfo.wasSuccessful == false">{{ updateUserInfo.message }}</p>
                    </div><!-- end profile-setting-panel -->
                </div><!-- end tab-pane -->
                
                <div class="tab-pane fade" id="change-password" role="tabpanel" aria-labelledby="change-password-tab">
                    <div class="profile-setting-panel">
                        <h5 class="mb-4">Change Password</h5>
                        <div class="mb-3">
                            <label for="oldPassword" class="form-label">Old Password</label>
                            <div class="position-relative">
                                <input type="password" class="form-control form-control-s1" id="oldPassword" placeholder="Old password" v-model="passwordInfo.oldPassword">
                                <a href="oldPassword" class="password-toggle" title="Toggle show/hide pasword">
                                    <em class="password-shown ni ni-eye-off"></em>
                                    <em class="password-hidden ni ni-eye"></em>
                                </a>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="newPassword" class="form-label">New Password</label>
                            <div class="position-relative">
                                <input type="password" class="form-control form-control-s1" id="newPassword" placeholder="New password" v-model="passwordInfo.newPassword">
                                <a href="newPassword" class="password-toggle" title="Toggle show/hide pasword">
                                    <em class="password-shown ni ni-eye-off"></em>
                                    <em class="password-hidden ni ni-eye"></em>
                                </a>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="confirmNewPassword" class="form-label">Confirm New Password</label>
                            <div class="position-relative">
                                <input type="password" class="form-control form-control-s1" id="confirmNewPassword" placeholder="Confirm new password" v-model="passwordInfo.newPasswordConfirm">
                                <a href="confirmNewPassword" class="password-toggle" title="Toggle show/hide pasword">
                                    <em class="password-shown ni ni-eye-off"></em>
                                    <em class="password-hidden ni ni-eye"></em>
                                </a>
                            </div>
                        </div>
                        <button class="btn btn-primary mt-3" type="button" @click="changePassword()">Update Password</button>
                        <p class="text-success mt-2" v-if="passwordInfo.wasSuccessful == true">{{ passwordInfo.message }}</p>
                        <p class="text-danger mt-2" v-if="passwordInfo.wasSuccessful != null && passwordInfo.wasSuccessful == false">{{ passwordInfo.message }}</p>
                    </div><!-- end profile-setting-panel -->
                </div><!-- end tab-pane -->
                <div class="tab-pane fade" id="validate-profile" role="tabpanel" aria-labelledby="validate-profile-tab">
                    <div class="profile-setting-panel">
                        <h5 class="mb-4">Verify your profile</h5>
                        <p class="mb-3 fs-14">Verify your unique identity by linking your phone number with your account. Once your profile is validated, you will be approved to participate in all drops that require profile validation to purchase.</p>
                        <p class="mb-4 fs-14">(Please note, profile validation is not 2fa and not used for security purposes.)</p>
                        <hr class="my-4">
                        <h6 class="mb-3 fw-semibold">Enter phone number to receive code</h6>
                        <ul class="btns-group">
                            <li>
                                <input type="text" class="form-control form-control-s1 phone-number" placeholder="Phone number">
                            </li>
                            <li>
                                <button class="btn btn-primary" type="button">Submit</button>
                            </li>
                        </ul>
                    </div><!-- end profile-setting-panel -->
                </div><!-- end tab-pane -->
            </div><!-- end tab-content -->
        </div><!-- end profile-setting-panel-wrap-->
    </div><!-- end col-lg-8 -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import { mapGetters } from "vuex";
// import vueFilePond from 'vue-filepond';
// import 'filepond/dist/filepond.min.css';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// const FilePond = vueFilePond(FilePondPluginImagePreview);
export default {
  name: 'AccountSection',
  data () {
    return {
      SectionData,
      passwordInfo: {
        oldPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        wasSuccessful: null,
        message: null
      },
      updateUserInfo: {
        wasSuccessful: null,
        message: "",
        profilePicture: null
      }
    }
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    isLocationResolved() {
      return this.$store.state.meta.isLocationResolved;
    },
    ...mapGetters({
      user: "auth/authUser",
    }),
  },
  components: {
    // FilePond
  },
  methods: {
    updateUser() {
        const files = this.$refs.pond.getFiles();
        if (files != null && files.length > 0) {
            this.user.avatar = files[0].filename;
        }
        this.$store.dispatch('auth/updateUser', this.user)
            .then((res) => {
                // this.$toasted.success('Profile Successfuly Updated', { duration: 3000 })
                this.updateUserInfo.wasSuccessful = true;
                this.updateUserInfo.message = "Profile updated successfully"
            })
            .catch(err => {
                console.log(err)
                
                this.updateUserInfo.wasSuccessful = false;
                this.updateUserInfo.message = "An error has occured, try again later"
            })
    },
    changePassword() {
        this.$store.dispatch('auth/changePassword', this.passwordInfo)
            .then((res) => {
                // this.$toasted.success('Profile Successfuly Updated', { duration: 3000 })
                this.passwordInfo.wasSuccessful = res.data.wasSuccessful;
                this.passwordInfo.message = res.data.message;
                this.passwordInfo.oldPassword = "";
                this.passwordInfo.newPassword = "";
                this.passwordInfo.newPasswordConfirm = "";
            })
            .catch(err => {
                console.log(err)
            })
    },
    handleFilePondInit: function() {
        //console.log('FilePond has initialized');
    },
  },
  mounted () {
    /*===========SHOW UPLOADED IMAGE ================== */
    // function uploadImage (selector) {
    //   let elem = document.querySelectorAll(selector)
    //   if (elem.length > 0) {
    //     elem.forEach(item => {
    //       item.addEventListener('change', function () {
    //         if (item.files && item.files[0]) {
    //           let img = document.getElementById(item.dataset.target)
    //           img.onload = function () {
    //             URL.revokeObjectURL(img.src)
    //           }
    //           img.src = URL.createObjectURL(item.files[0])

    //           let allowedExtensions = ['jpg', 'JPEG', 'JPG', 'png']
    //           let fileExtension = this.value.split('.').pop()
    //           var lastDot = this.value.lastIndexOf('.')
    //           var ext = this.value.substring(lastDot + 1)
    //           var extTxt = img.value = ext
    //           if (!allowedExtensions.includes(fileExtension)) {
    //             alert(extTxt + ' file type not allowed, Please upload jpg, JPG, JPEG, or png file')
    //             img.src = ' '
    //           }
    //         }
    //       })
    //     })
    //   }
    // }

    // uploadImage('.upload-image')

    /* =========== Show/Hide passoword ============== */
    function showHidePassword(selector){
    let elem = document.querySelectorAll(selector);
    if(elem.length > 0){
        elem.forEach(item => {
        item.addEventListener("click", function(e){
            e.preventDefault();
            let target = document.getElementById(item.getAttribute("href"));
            if(target.type == "password") {
            target.type = "text";
            item.classList.add("is-shown");
            }else{
            target.type = "password";
            item.classList.remove("is-shown");
            }
        });

        });
    ``}
    }

    showHidePassword(".password-toggle");

  }
}
</script>
