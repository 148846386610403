// import * as Vue from 'vue'
import axiosInstance from '@/services/axios'
import { rejectError } from '@/helpers'

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {
  },
  actions: {
    createModel ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/create', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    getUsersDreamBoothModels ({rootState}) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getusersdreamboothmodels')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },

    getBaseDreamBoothModels ({rootState}) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getbasedreamboothmodels')
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getUserProfilePosts ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getuserprofileposts?username=' + params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    createUserProfilePost ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/createuserprofilepost', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getPredictionsForDreamBoothModel ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getpredictionsfordreamboothmodel?modelId=' + params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getDreamBoothModel ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getdreamboothmodel?modelId=' + params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    generateDreamBoothImage ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/generatedreamboothimage', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getDreamBoothPrediction ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getdreamboothprediction?username=' + params.username + '&url=' + params.url)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    deleteDreamBoothImage ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/deletedreamboothimage', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    deleteDreamBoothUpscale ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/deletedreamboothupscale', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    useUpscaleForProductImages ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/useupscaleforproductimages', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    deleteUserProfilePost ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/deleteuserprofilepost', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    deleteUserDreamBoothModel ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/deleteuserdreamboothmodel', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    changeUserDreamBoothModelPrivacy ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/changeuserdreamboothmodelprivacy', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    upscaleDreamBoothImage ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.post('/api/v1/models/upscaledreamboothimage', params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getReplicatePrediction ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getreplicateprediction?id=' + params)
        .then((data) => {
          return data
        })
        .catch(err => console.log(err))
    },
    getReplicateUpscalePrediction ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getreplicateupscaleprediction?id=' + params)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    },
    getProductByPredictionUrlAndTitle ({rootState}, params) {
      if (rootState.auth && rootState.auth.user) {
        // params.user = rootState.auth.user
      }
      return axiosInstance.get('/api/v1/models/getproductbypredictionurlandtitle?url=' + params.url + "&title=" + params.title + "&username=" + params.username)
        .then((data) => {
          return data
        })
        .catch(err => rejectError(err))
    }
  }
}