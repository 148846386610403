import { createStore } from "vuex";
import auth from './modules/auth'
import stats from './modules/stats'
import meta from './modules/meta'
import api from './modules/api'
import model from './modules/model'
import user from './modules/user'
import material from './modules/material'
import blog from './modules/blog'
import chat from './modules/chat'

export default createStore({
  modules: {
    auth,
    stats,
    meta,
    api,
    model,
    user,
    material,
    blog,
    chat
  },
  mutations: {
    setItems (state, {resource, items}) {
      state[resource].items = items
    },
    setItem (state, {resource, item}) {
      state[resource].item = item
    },
    // addItemToArray (state, {item, index, resource}) {
    //   Vue.set(state[resource].items, index, item)
    // }
  }
})
