<template>
<section class="related-product-section section-space-b">
    <div class="container">
        <!-- section heading -->
        <!-- <SectionHeading classname="text-center" :text="'Products'"></SectionHeading> -->
        <!-- product -->
        <!-- <p class="mb-2" v-if="!productData.noMessage">Any order helps me keep the site up ❤️</p> -->
        <swiper v-if="productData && productData.products"
            :slides-per-view="4"
            
            :pagination="{
                dynamicBullets: false,
                clickable: true
            }"
            :breakpoints="{
              0: {
                  slidesPerView: 2
              },
              767: {
                  slidesPerView: 2
              },
              992: {
                  slidesPerView: 3
              },
              1200: {
                  slidesPerView: 4
              }
            }">
            <swiper-slide v-for="item in productData.products" :key="item._id">
                <div class="card card-full bg-white  mb-4">
                    <div class="card-image">
                        <img v-if="item.printifyBlueprintId == 421" v-lazy="item.images[20].src" class="card-img-top" :alt="item.prompt + ' ' + item.title">
                        <img v-if="item.printifyBlueprintId == 50" v-lazy="item.images[29].src" class="card-img-top" :alt="item.prompt + ' ' + item.title">
                        <img v-if="item.printifyBlueprintId != 421 && item.printifyBlueprintId != 50" v-lazy="item.images[0].src" class="card-img-top" :alt="item.prompt + ' ' + item.title">
                    </div>
                    <div class="card-body p-2 bg-primary rounded-bottom">
                        <h5 class="card-title text-truncate mb-0 text-white">{{ item.title  }}</h5>
                        <!-- <div class="card-author mb-1 d-flex align-items-center">
                            <span class="me-1 card-author-by">By</span>
                            <div class="custom-tooltip-wrap">
                                <router-link :to="item.authorLink" class="author-link">{{ item.author }}</router-link>
                            </div>
                        </div> -->
                        <div class="card-price-wrap d-flex align-items-center justify-content-between mt-3">
                          <!-- <div>
                              <span class="card-price-title">Current bid</span>
                              <span class="card-price-number">{{ item.priceTwo }} ETH</span>
                          </div> -->
                          <div class="me-2">
                              <span class="card-price-number text-white">&dollar;{{ item.price / 100 }}</span>
                          </div>
                          <router-link :to="{
                                name: 'GenerationProductDetail',
                                params: {
                                generationurl: item.generationUrl,
                                title: item.title
                                }
                            }">
                            <span class="btn btn-sm btn-light">Buy now</span>
                          </router-link>

                        </div>
                        <span class="card-price-title text-white mt-2" :style="'font-size: 12px; float: right'">Free Shipping</span>
                    </div><!-- end card-body -->
                <router-link
                    class="details"
                    :to="{
                        name: 'GenerationProductDetail',
                        params: {
                        generationurl: item.generationUrl,
                        title: item.title
                        }
                    }"
                >
                </router-link>
                </div><!-- end card -->
            </swiper-slide>
        </swiper>
    </div><!-- .container -->
</section><!-- end related-product-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
// core version + navigation, pagination modules:
import SwiperCore, { Pagination } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'RelatedProduct',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
        productData: Object
    },
  data() {
    return {
      SectionData
    }
  },
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .author-linkm,
 .card-price-wrap {
   z-index: 2;
   position: relative;
 }
</style>
