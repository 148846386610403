<template>
  <div class="row g-gs">
    <div class="col-xl-3 col-lg-4 col-sm-6" v-for="item in items" :key="item._id">
        <ProductsThree :item="item"></ProductsThree>
    </div>
  </div>
</template>

<script>
import SectionData from '@/store/store.js'
import ProductsThree from '@/components/section/ProductsThree'

export default {
  name: 'ProductsContainerThree',
  components: { 
    ProductsThree 
  },
  data () {
      return {
        SectionData,
        items: {}
      }
  },
  beforeMount() {
    const data = {
        sort: "download_count"
    }
    this.$store.dispatch("api/getGutenbergBooks", data)
    .then((res) => {
      this.items = res.data.slice(0,8)
      this.isLoading = false;
    });
  }

};
</script>