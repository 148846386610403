<template>
    <ul class="menu-list ms-lg-auto">
          <!-- <li class="menu-item has-sub">
              <a href="#" class="menu-link menu-toggle">{{ SectionData.headerData.menuList.title }}</a>
              <div class="menu-sub">
                 <ul class="menu-list">
                      <li class="menu-item" v-for="nav in SectionData.headerData.menuList.navList" :key="nav.id"><router-link :to="nav.path" class="menu-link">{{ nav.title }} <span :class="nav.badgeClass" v-if="nav.badge">{{ nav.badge }}</span></router-link></li>
                 </ul>
              </div>
          </li> -->
          
          <li class="menu-item">
            <router-link :to="'/'" class="menu-link"><span>Home</span></router-link>
          </li>
          
          <li class="menu-item">
            <a class="menu-link" :href="'https://chat.teachnice.com'" target="_blank">AI Assitant</a>
          </li>
          <!-- <li class="menu-item has-sub">
              <a href="#" class="menu-link menu-toggle">{{ SectionData.headerData.menuList2.title }}</a>
              <div class="menu-sub">
                 <ul class="menu-list">
                      <li class="menu-item" v-for="nav in SectionData.headerData.menuList2.navList" :key="nav.id"><router-link :to="nav.path" class="menu-link">{{ nav.title }} <span :class="nav.badgeClass" v-if="nav.badge">{{ nav.badge }}</span></router-link></li>
                 </ul>
              </div>
          </li> -->

          <!-- <li class="menu-item">
              <router-link :to="'/dashboard'" class="menu-link"><span>Dashboard</span></router-link>
          </li> -->
          <li class="menu-item has-sub">
            <router-link :to="'/books'" class="menu-link"><span>Book Materials</span></router-link>
              <div class="menu-sub menu-mega">
                <div class="menu-mega-row">
                    <ul class="menu-list menu-list-mega">
                        <li class="menu-item" v-for="nav in this.generalSubjects1" :key="nav"><router-link :to="'/books/' + nav.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-')" class="menu-link">{{ nav }}</router-link></li>
                    </ul>
                    <ul class="menu-list menu-list-mega">
                        <li class="menu-item" v-for="nav in this.generalSubjects2" :key="nav"><router-link :to="'/books/' + nav.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-')" class="menu-link">{{ nav }}</router-link></li>
                    </ul>
                    <ul class="menu-list menu-list-mega">
                        <li class="menu-item" v-for="nav in this.generalSubjects3" :key="nav"><router-link :to="'/books/' + nav.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-')" class="menu-link">{{ nav }}</router-link></li>
                    </ul>
                </div>
              </div>
          </li>
          <!-- <li class="menu-item">
              <router-link :to="'/blog'" class="menu-link"><span>Blog</span></router-link>
          </li> -->
          <!-- <li class="menu-item">
              <router-link :to="'/generate'" class="menu-link"><span>Free Generator</span></router-link>
          </li> -->
          <!-- <li class="menu-item">
              <router-link :to="'/generate'" class="menu-link"><span>Create</span></router-link>
          </li> -->
          <!-- <li class="menu-item has-sub">
              <a href="#" class="menu-link menu-toggle">{{ SectionData.headerData.menuList3.title }}</a>
              <div class="menu-sub">
                 <ul class="menu-list">
                      <li class="menu-item" v-for="nav in SectionData.headerData.menuList3.navList" :key="nav.id"><router-link :to="nav.path" class="menu-link">{{ nav.title }}</router-link></li>
                 </ul>
              </div>
          </li> -->

          <!-- ADD LOGIC FOR WHEN SIGNED IN-->
          <li class="menu-item has-sub">
            <router-link :to="'/blog'" class="menu-link menu-toggle"><span>Blog</span></router-link>
              <div class="menu-sub menu-mega">
                <div class="menu-mega-row">
                    <ul class="menu-list menu-list-mega">
                        <li class="menu-item" v-for="nav in this.categories1" :key="nav"><router-link :to="'/blog/category/' + nav.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-')" class="menu-link">{{ nav }}</router-link></li>
                    </ul>
                    <ul class="menu-list menu-list-mega">
                        <li class="menu-item" v-for="nav in this.categories2" :key="nav"><router-link :to="'/blog/category/' + nav.toLowerCase().replaceAll('', '').replace(/[^a-z0-9]+/g, '-')" class="menu-link">{{ nav }}</router-link></li>
                    </ul>
                </div>
              </div>
          </li>
     </ul>
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
// import { mapGetters } from "vuex";

export default {
  name: 'MenuList',
  data () {
    return {
      SectionData,
      generalSubjects1: [
      "Classic Literature",
      "Science Fiction",
      "Fantasy",
      "Romance",
      "Historical Fiction",
      "Mystery & Detective Stories",
      "Poetry",
      "Adventure",
      "Horror",
      "Biographies & Autobiographies",
      "Children's Literature",
      "Drama",
      "Mythology",
      "Westerns",
      "War & Military",
      "Religion",
      "Philosophical literature",
    ],
    generalSubjects2: [
      "Educational literature",
      "Non-fiction",
      "History",
      "Cultural studies",
      "Political Analysis",
      "Comedy",
      "Short Stories",
      "Science & Natural History",
      "Travelogues",
      "Art",
      "Music",
      "Cooking & Cuisine",
      "Health & Fitness",
      "Economics",
      "Literary Criticism",
      "Linguistics",
      "Technology & Engineering",
    ],
    generalSubjects3: [
      "Mathematics",
      "Psychology & Psychiatry",
      "Philosophy",
      "Legal Studies",
      "Sociology",
      "Biology",
      "Chemistry",
      "Physics",
      "Self-help",
      "Sustainable Living",
      "Anthropology",
      "Sports & Recreation",
      "Theater",
      "Astronomy",
      "Agriculture",
      "Archaeology"
    ],

      categories1: [
      "Teaching Strategies",
      "Curriculum and Lesson Planning",
      "Classroom Management",
      "Education Technology",
      "Professional Development",
      "Diversity and Inclusion",
      "Classroom Resources",
      "Special Education",
      "Student Assessment",
      "Health and Physical Education",
      "Education Policies",
      "Parent-Teacher Relations",
      "Literacy and Reading Education",
      "Student Motivation",
      "School Leadership",
      "Home Schooling",
      "Teacher Wellness",
      "Financial Education",
      "Gifted and Talented Education",
     ],
      categories2: [
      "Language Education",
      "Science Education",
      "Math Education",
      "History and Social Studies Education",
      "Arts and Crafts",
      "Music and Performing Arts",
      "Student Wellness",
      "Education News and Trends",
      "Teaching in the Digital Age",
      "Learning Games and Activities",
      "School Safety",
      "School Events and Activities",
      "Teacher Ethics",
      "Teacher Life",
      "Education Philosophy and Systems",
      "English Language Learners",
      "Education Policy and Advocacy"
     ]
    }
  }//,
  // computed: {
  //   ...mapGetters({
  //     user: "auth/authUser",
  //   }),
  // }
}
</script>
