<template>
    <div class="card card-full bg-light border">
        <div class="card-body text-center">
            <div class="card-image mb-2" style="height: 120px;">
                <img v-lazy="item.formats['image/jpeg']" class="card-img-top w-50" style="max-height: 120px; min-height: 120px;" :alt="item.title">
            </div>
            <div class="mt-auto">
                <h5 class="card-title mb-2 text-truncate">{{ item.title }}</h5>
                <span class="me-1 card-author-by text-black">{{ item.authors.length > 0 ? "" + formatName(item.authors[0].name) : " "}}</span>
            </div>
        </div>
        <router-link
            class="details"
            :to="'/book/' + item.url"
        >
        </router-link>
    </div><!-- end card -->
</template>
<script>
import { createPopper } from '@popperjs/core';

export default {
  name: 'BookDirectoryItem',
  props: ['item'],
  mounted () {
    /*============= Custom Tooltips =============== */
    function customTooltip(selector, active) {
    let elem = document.querySelectorAll(selector);
        if(elem.length > 0){
            elem.forEach(item => {
                const parent = item.parentElement;
                const next = item.nextElementSibling;
                createPopper(item, next);
                parent.addEventListener("mouseenter", function() {
                    parent.classList.add(active)
                });
                parent.addEventListener("mouseleave", function() {
                    parent.classList.remove(active)
                });
            });
        }
    }

    customTooltip('.custom-tooltip','active');

  },
  computed: {
  },
  methods: {
    formatName(name) {
        // Split the name into an array using the comma as a delimiter
        var nameArray = name.replace(/\([^()]*\)/g, '').split(", ");

        // Extract the first and last name from the array
        var lastName = nameArray[0];
        var firstName = nameArray[1];

        
        if (lastName && firstName) {
            // Return the formatted name
            return firstName + " " + lastName;   
        } else {
            return name;
        }
    }
  }
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .author-link,
 .card-price-wrap {
   z-index: 2;
   position: relative;
 }

 .card:hover {
    scale: 103%;
 }

 .card:hover h5{
    color: #7952B3;
    white-space: pre-wrap;
    overflow:visible;
    transition: .4s;
 }
</style>