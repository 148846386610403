<template>
    <div class="card card-full">
        <div class="card-body p-4">
            <h5 class="card-title mb-2">{{ item.title }}</h5>
            <div class="card-image mb-2">
                <img v-lazy="item.formats['image/jpeg']" class="card-img-top w-75 mb-2" :alt="item.title">
            </div>
            <div class="card-author mb-1 d-flex align-items-center position-absolute bottom-0 start-10">
                <span class="me-1 card-author-by">{{ item.authors.length > 0 ? "" + formatName(item.authors[0].name) : " "}}</span>
                <!-- <div class="custom-tooltip-wrap">
                    <router-link :to="item.views" class="custom-tooltip author-link">{{ product.author }}</router-link>
                    <div class="card-generic custom-tooltip-dropdown">
                        <div class="author-action d-flex flex-wrap align-items-center mb-3">
                            <div class="flex-shrink-0 avatar">
                                <img :src="product.avatar" alt="avatar">
                            </div>
                            <div class="ms-2">
                                <span class="author-username">Anonymous</span>
                                <span class="author-follow-text">Hello</span>
                            </div>
                        </div>
                        <h6 class="author-name mb-1">{{ item.views }}</h6>
                        <p class="author-desc smaller mb-3">{{ item.views }}</p>
                        <div class="follow-wrap mb-3">
                            <h6 class="mb-1 smaller text-uppercase">Followed by</h6>
                            <div class="avatar-group">
                                <router-link :to="avatar.path" v-for="avatar in item.avatars" :key="avatar.id">
                                    <img :src="avatar.img" alt="avatar">
                                </router-link>
                            </div>
                        </div>
                        <router-link :to="item.views" class="btn btn-sm bg-dark-dim">Follow</router-link>
                    </div>
                </div> -->
            </div>
            <!-- <div class="card-price-wrap d-flex align-items-center justify-content-sm-between mb-3">
                <div class="me-5 me-sm-2">
                    <span class="card-price-title">Created on</span>
                    <span class="card-price-number">{{ item.createdOnDate }}</span>
                </div>
                <div class="text-sm-end">
                    <span class="card-price-title"><i class="ni ni-eye"></i> {{ item.views }}</span>
                    <span class="card-price-number"></span>
                </div>
                <a v-if="user != null && user.isAdmin" class="btn btn-sm btn-outline-danger" data-bs-toggle="modal" :data-bs-target="'#deleteModal'" @click="deleteGeneration(item)"><em class="ni ni-trash icon"></em></a>

            </div> -->
            <!-- <span class="btn btn-sm btn-dark">Place Bid</span> -->
        </div><!-- end card-body -->
        <router-link
            class="details"
            :to="'/book/' + item.url"
        >
        </router-link>
    </div><!-- end card -->
</template>
<script>
import { createPopper } from '@popperjs/core';
import { mapGetters } from "vuex";

export default {
  name: 'ExploreItems',
  props: ['item'],
  mounted () {
    /*============= Custom Tooltips =============== */
    function customTooltip(selector, active) {
    let elem = document.querySelectorAll(selector);
        if(elem.length > 0){
            elem.forEach(item => {
                const parent = item.parentElement;
                const next = item.nextElementSibling;
                createPopper(item, next);
                parent.addEventListener("mouseenter", function() {
                    parent.classList.add(active)
                });
                parent.addEventListener("mouseleave", function() {
                    parent.classList.remove(active)
                });
            });
        }
    }

    customTooltip('.custom-tooltip','active');

  },
  computed: {
      isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
      },
      isLocationResolved() {
      return this.$store.state.meta.isLocationResolved;
      },
      ...mapGetters({
          user: "auth/authUser",
      }),
  },
  methods: {
    async deleteGeneration(generation) {
      await this.$store.dispatch("api/deleteGeneration", generation)
        .then((res) => {
            console.log(res.data)
        });
    },
    formatName(name) {
        // Split the name into an array using the comma as a delimiter
        var nameArray = name.replace(/\([^()]*\)/g, '').split(", ");

        // Extract the first and last name from the array
        var lastName = nameArray[0];
        var firstName = nameArray[1];

        
        if (lastName && firstName) {
            // Return the formatted name
            return firstName + " " + lastName;   
        } else {
            return name;
        }
    }
  }
}
</script>

<style lang="css" scoped>
 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .author-link,
 .card-price-wrap {
   z-index: 2;
   position: relative;
 }
</style>